export const TYPE_VK = 1;
export const TYPE_FB = 2;
export const TYPE_GO = 3;
export const TYPE_SMS = 4;

const socialData = {};
socialData[TYPE_VK] = {
    icon: 'vk',
    url: `https://vk.com/`,
    name: 'Вконтакте',
};
socialData[TYPE_FB] = {
    icon: 'fb',
    url: `https://facebook.com/`,
    name: 'Facebook',
};
socialData[TYPE_GO] = {
    icon: 'google',
    url: `https://google.com/`,
    name: 'Google',
};
socialData[TYPE_SMS] = {
    icon: '',
    url: `https://iwengo.ru/`,
    name: 'sms',
};

/**
 * Миксин для социальных сетей
 */
export default {
    methods: {
        /**
         * Получить тип социальной сети по идентификатору
         * @param {*} identifier
         */
        socialType(identifier) {
            switch (identifier) {
                case TYPE_VK:
                case 'vkontakte':
                    return TYPE_VK;
                case TYPE_FB:
                case 'facebook':
                    return TYPE_FB;
                case TYPE_GO:
                case 'google':
                    return TYPE_GO;
                case TYPE_SMS:
                case 'sms':
                    return TYPE_SMS;
                default:
                    return undefined;
            }
        },

        /**
         * Получить имя иконки для социальной сети по идентификатору
         * @param {*} identifier
         */
        socialIcon(identifier) {
            const type = this.socialType(identifier);
            return socialData[type].icon;
        },

        /**
         * Получить урл социальной сети по идентификатору
         * @param {*} identifier
         */
        socialUrl(identifier) {
            const type = this.socialType(identifier);
            return socialData[type].url;
        },

        /**
         * Получить текст социальной сети по идентификатору
         * @param {*} identifier
         */
        socialTitle(identifier) {
            const type = this.socialType(identifier);
            return socialData[type].name;
        },
    },
};
