var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.layout_ShowFooter
    ? _c(
        "footer",
        { staticClass: "footer-main" },
        [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              {
                staticClass: "footer-main__wrapper footer-main__wrapper--main"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "footer-main__wrapper footer-main__wrapper--col"
                  },
                  [
                    _c("v-svg", { attrs: { name: "logo-footer" } }),
                    _vm._v(" "),
                    !_vm.isDesktop
                      ? _c(
                          "ul",
                          { staticClass: "footer-main__agreement text-sm" },
                          [
                            _vm._l(_vm.agreementLinks, function(item) {
                              return _c(
                                "li",
                                { staticClass: "footer-main__agreement-item" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "footer-main__link link",
                                      attrs: {
                                        target: "_blank",
                                        href: item.link
                                      }
                                    },
                                    [_vm._v(_vm._s(item.title))]
                                  )
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "li",
                              { staticClass: "footer-main__agreement-item" },
                              [_vm._v("© iWENGO " + _vm._s(_vm.year) + " ")]
                            )
                          ],
                          2
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "footer-main__wrapper footer-main__wrapper--col"
                  },
                  [
                    _c(
                      "ul",
                      { staticClass: "footer-main__list" },
                      _vm._l(_vm.layout_MenuBottom, function(item) {
                        return item && item.href
                          ? _c("li", { staticClass: "footer-main__item" }, [
                              _c("a", { attrs: { href: item.href } }, [
                                _vm._v(_vm._s(item.name))
                              ])
                            ])
                          : _vm._e()
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "footer-main__wrapper footer-main__wrapper--bottom"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "footer-main__news" },
                          [
                            _c(
                              "v-input",
                              {
                                staticClass: "footer-main__news-input",
                                attrs: {
                                  type: "email",
                                  placeholder: "Введите E-mail",
                                  disabled: !_vm.auth_IsGuest
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "after",
                                      fn: function() {
                                        return [
                                          _c("v-button", {
                                            staticClass:
                                              "footer-main__news-btn",
                                            attrs: {
                                              "icon-name": "arrow",
                                              "aria-label":
                                                "Подписаться на новости"
                                            },
                                            on: { click: _vm.subscribe }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3567390045
                                ),
                                model: {
                                  value: _vm.subscriber.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.subscriber, "email", $$v)
                                  },
                                  expression: "subscriber.email"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "footer-main__news-label" },
                                  [_vm._v("Подпишись на новости")]
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "footer-main__contacts" },
                          [
                            _c(
                              "v-button",
                              {
                                staticClass: "footer-main__contact-btn",
                                on: {
                                  click: function($event) {
                                    return _vm.modal_ChangeState({
                                      name: "claim",
                                      open: true,
                                      state: { type: "collaboration" }
                                    })
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            Сотрудничество\n                        "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "footer-main__contacts footer-main__contacts--no-wrap"
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "footer-main__socials" },
                                  _vm._l(_vm.socialIcons, function(icon) {
                                    return _c("li", { key: icon.name }, [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: icon.url,
                                            "aria-label": icon.name,
                                            rel: icon.rel,
                                            target: "_blank"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.sendMetric(
                                                "click_social",
                                                "click",
                                                "social"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("v-svg", {
                                            attrs: { name: icon.icon }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  }),
                                  0
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "footer-main__contact" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "comagic_phone",
                                        attrs: { href: _vm.mainPhoneHref },
                                        on: {
                                          click: function($event) {
                                            _vm.showPhoneCallback()
                                            _vm.sendMetric(
                                              "click_phone",
                                              "click",
                                              "phone"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.layout_StaticBlock.main_phone
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._m(0),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href:
                                            "mailto:" +
                                            _vm.layout_StaticBlock.main_email
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.layout_StaticBlock.main_email
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(
                                        "отвечаем ежедневно в течение суток"
                                      )
                                    ])
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.isDesktop
                      ? _c(
                          "ul",
                          { staticClass: "footer-main__agreement text-sm" },
                          [
                            _vm._l(_vm.agreementLinks, function(item) {
                              return _c(
                                "li",
                                { staticClass: "footer-main__agreement-item" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "footer-main__link link",
                                      attrs: {
                                        target: "_blank",
                                        href: _vm.layout_StaticBlock[item.code]
                                      }
                                    },
                                    [_vm._v(_vm._s(item.title))]
                                  )
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "li",
                              { staticClass: "footer-main__agreement-item" },
                              [_vm._v("© iWENGO " + _vm._s(_vm.year) + " ")]
                            )
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("modal-form-subscribe", {
            attrs: { email: _vm.subscriber.email },
            on: {
              showPostLoader: function($event) {
                _vm.show_post_loader = $event
              }
            }
          }),
          _vm._v(" "),
          _c("modal-form-claim", {
            on: {
              showPostLoader: function($event) {
                _vm.show_post_loader = $event
              }
            }
          }),
          _vm._v(" "),
          _c("modal-phone-callback", {
            on: {
              showPostLoader: function($event) {
                _vm.show_post_loader = $event
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.show_post_loader,
                  expression: "show_post_loader"
                }
              ],
              staticClass: "post-loader"
            },
            [_c("loader")],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-3" }, [
      _vm._v("понедельник-пятница с 10 до 19"),
      _c("br"),
      _vm._v("по Московскому времени")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }