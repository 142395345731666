import Vue from 'vue';

Vue.directive('focus', {
    // Когда привязанный элемент вставлен в DOM...
    inserted(el, binding) {
        if (binding.value) {
            const { active } = binding.value;
            if (!active) return;
        }

        const delay = binding.value ? binding.value.delay : Number.NaN;
        const time = Number(delay);
        const query = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
        let timeout = null;
        let focusable = null;

        // Переключаем фокус на элемент
        const isFocusable =
            el instanceof HTMLInputElement ||
            el instanceof HTMLTextAreaElement ||
            el instanceof HTMLSelectElement ||
            el instanceof HTMLButtonElement ||
            el instanceof HTMLLinkElement ||
            el.tabIndex > -1;

        focusable = isFocusable ? el : el.querySelector(query);
        if (focusable)
            if (!Number.isNaN(time)) {
                timeout = setTimeout(() => {
                    focusable.focus();
                    clearTimeout(timeout);
                }, time);
            } else focusable.focus();
    },
});
