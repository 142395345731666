<template>
    <form class="form form-login" @submit.prevent="sendForm" novalidate>
        <div class="form-login__container form-login__header">
            <h3 class="form__hl form-login__hl">Войти</h3>
            <v-link class="form-login__register" href="/profile/register">Регистрация</v-link>
        </div>
        <div class="form-login__container">
            <v-input
                class="form__col"
                v-model="email"
                v-focus="{ active: autofocus, delay: 200 }"
                placeholder="Введите ваш email"
                :error="email_error"
                aria-required="true"
            >
                Логин<span aria-hidden="true">*</span>
                <template v-slot:error="{ error }">
                    <transition name="slide-in-bottom" mode="out-in">
                        <div :key="error" v-if="error">{{ error }}</div>
                    </transition>
                </template>
            </v-input>
            <v-password
                class="form__col form-login__password"
                v-model="password"
                placeholder="Введите пароль"
                :error="password_error"
                aria-required="true"
            >
                <div class="form-login__password-label">
                    <span> Пароль<span aria-hidden="true">*</span> </span>
                    <v-link @click="modal_ChangeState({ name: 'forgot_password', open: true })" class="link--sm">
                        Забыли пароль?
                    </v-link>
                </div>
                <template v-slot:error="{ error }">
                    <transition name="slide-in-bottom" mode="out-in">
                        <div :key="error" v-if="error">{{ error }}</div>
                    </transition>
                </template>
            </v-password>

            <div class="form__cols form__submit form-login__submit">
                <div class="form__col">
                    <input v-model="remember" type="checkbox" id="form-login__checkbox" />
                    <label class="form-login__submit-label" for="form-login__checkbox">Запомнить меня</label>
                </div>
                <v-button class="form__col form-login__submit-btn" type="submit">Войти</v-button>
            </div>
            <div class="error-message form__col form-login__error">
                <transition name="slide-in-bottom" mode="out-in" appear>
                    <div :key="error" role="alert" v-if="error">
                        {{ error }}
                    </div>
                </transition>
            </div>
        </div>
        <div class="form-login__container form-login__footer">
            <div class="form-login__footer-body">
                <h3 class="form-login__footer-hl">Войти через соц. сети или СМС:</h3>
                <div v-if="socials && socials.length === 0">Нет прикрепленных социальных сетей.</div>
                <v-list v-else class="form-login__footer-list" key-field="name" :items="socials">
                    <template v-slot:item="{ item }">
                        <v-link
                            class="form-login__footer-link"
                            :href="item.link"
                            :iconName="socialIcon(item.name)"
                            :title="`Войти через ${item.title}`"
                            :aria-label="`Войти через ${item.title}`"
                        >
                            <div v-if="item.name == 'sms'" @click.prevent="modal_ChangeState({ name: 'sms_login', open: true })" class="form-login__sms">
                                <v-svg name="sms"/>
                            </div>
                        </v-link>
                    </template>
                </v-list>
            </div>
        </div>
        <div v-show="show_post_loader" class="post-loader">
            <loader />
        </div>
    </form>
</template>

<script>
import VButton from '../controls/VButton/VButton.vue';
import VList from '../controls/VList/VList.vue';
import VLink from '../controls/VLink/VLink.vue';
import VPassword from '../controls/VPassword/VPassword.vue';
import VInput from '../controls/VInput/VInput.vue';
import Modal from '../controls/modal/modal.vue';
import VSvg from '../controls/VSvg/VSvg.vue';
import Loader from '../loader/loader.vue';

import AuthMixin from '../../mixins/auth.js';
import SocialMixin from '../../mixins/social.js';
import ModalMixin from '../../mixins/modal';
import { validationMixin, email, password, required, minLength } from '../../../scripts/validation';

import './form-login.css';

import { mapState, mapActions } from 'vuex';

export default {
    name: 'form-login',
    mixins: [validationMixin, SocialMixin, AuthMixin(), ModalMixin('login')],
    props: {
        autofocus: {
            type: Boolean,
        },
    },
    components: {
        VButton,
        VLink,
        VInput,
        VPassword,
        VList,
        VSvg,
        Modal,
        Loader,
    },
    validations: {
        email: {
            required,
            email,
        },
        password: {
            required,
            //password,
            minLength: minLength(8),
        },
    },
    data() {
        return {
            email: '',
            password: '',
            remember: false,
            error: this.auth_NeedAnotherLogin
                ? 'Вы пытаетесь войти в систему через социальную сеть, которая не прикреплена к аккаунту. Войдите в аккаунт через соцсети, указанные ниже или обычным образом, и прикрепите социальную сеть в настройках профиля'
                : '',
            show_post_loader: false,
        };
    },
    computed: {
        isTabletLg() {
            return this.$mq.tabletLg;
        },
        acceptDrivers() {
            const good_drivers = new Set(this.auth_SuggestDrivers);
            return this.auth_SocialDrivers.filter(d => good_drivers.has(d.name));
        },
        socials() {
            return this.auth_NeedAnotherLogin ? this.acceptDrivers : this.auth_SocialDrivers;
        },
        email_error() {
            if (this.$v.email.$dirty) {
                if (!this.$v.email.required) return 'Обязательное поле';
                if (!this.$v.email.email) return 'Формат name@domain.com';
            }
        },
        password_error() {
            if (this.$v.password.$dirty && this.$v.password) {
                if (!this.$v.password.required) return 'Обязательное поле';
                //if (!this.$v.password.password) return 'Как минимум 1 заглавная и строчная латинские буквы и 1 цифра';
                if (!this.$v.password.minLength) return 'Не менее 8 символов';
            }
        },
    },
    methods: {
        sendForm() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.show_post_loader = true;
                this.auth_Login({
                    email: this.email,
                    password: this.password,
                    remember: this.remember,
                }).then(data => {
                    this.show_post_loader = false;
                    if (data.status !== 'ok') this.error = data.error;
                    else {
                        if(location.pathname.startsWith('/profile')) {
                            location.href = '/profile';
                        } else {
                            location.reload();
                        }
                    }
                });
            }
        },
    },
    watch: {
        modal_IsOpen(value) {
            if (!value) {
                this.email = '';
                this.password = '';
                this.error = '';
                this.$v.$reset();
            }
        },
    },
    mounted() {

    }
};
</script>
