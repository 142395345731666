import { TYPE_DOCUMENT, TYPE_COURSE, TYPE_SERVICE, TYPE_CONSTRUCT, TYPE_COURSE_TARIFF } from '../../../scripts/cart-item';
import Cart from '../../../scripts/cart';

const initialState = {
    /**
     * Скидка
     */
    discount: 0,

    /**
     * Продукты в корзине
     */
    items: [],

    /**
     * Цена корзины
     */
    price: 0,
};

export default function(newState = {}) {
    return {
        namespaced: true,
        state: Object.assign(initialState, newState),
        getters: {
            /**
             * Проверка наличия продукта в корзине
             */
            inCart: state => (type, itemId) => {
                const item = Cart.cartBaseParams(type, itemId);
                const { items } = state;

                switch (type) {
                    case TYPE_COURSE:
                        return items.some(i => item.course_span_id === i.course_span_id);
                    case TYPE_DOCUMENT:
                        return items.some(i => item.document_id === i.document_id);
                    case TYPE_SERVICE:
                        return items.some(i => item.service_id === i.service_id);
                    case TYPE_CONSTRUCT:
                        return items.some(i => item.construct_id === i.construct_id);
                    case TYPE_COURSE_TARIFF:
                        return items.some(i => item.course_tariff_id === i.course_tariff_id);
                    default:
                        console.error(`unexpected cart item type: ${type}`, item);
                        return false;
                }
            },
        },
        mutations: {
            changeCartState(state, newCartState) {
                Object.assign(state, newCartState);
            },
        },
        actions: {
            /**
             * Положить продукт в корзину
             * @param {String} type
             * @param {*} itemId
             * @param {*} params
             */
            setToCart({ commit }, { type, id, params }) {
                const item = Cart.cartBaseParams(type, id);
                item.quantity = (params ? params.quantity : null) || 1;
                item.price_multi_id = (params && params.price_multi_id)? params.price_multi_id : null;
                item.load_items = true;
                return Cart.set(item).then(resp => {
                    commit('changeCartState', resp.cart);
                    if (params && params.redirect) window.location.href = '/cart';
                    return resp;
                });
            },

            /**
             * Удаление продукта по id из корзины
             * @param {*} id
             */
            removeFromCart({ commit }, id) {
                const params = {
                    item_id: id,
                    load_items: true,
                };
                return Cart.remove(params).then(resp => {
                    if (resp.ok) commit('changeCartState', resp.cart);
                    return resp;
                });
            },

            /**
             * Применить промокод
             */
            applyPromocode({ commit }, code) {
                return Cart.applyPromoCode(code).then(resp => {
                    if (resp.cart) commit('changeCartState', resp.cart);
                    return resp;
                });
            },

            /**
             * Очистить корзину
             */
            clear({ commit }) {
                return Cart.clear().then(resp => {
                    if (resp.ok) commit('changeCartState', resp.cart);
                    return resp;
                });
            },
        },
    };
}
