import { mapState, mapActions } from 'vuex';

/**
 * Метод создания миксина для модуля Auth
 */
export default (includeMethods = true, includeComputed = true) => {
    const mixin = {};
    if (includeMethods)
        mixin.methods = {
            ...mapActions('auth', {
                auth_Logout: 'logout',
                auth_Login: 'login',
                auth_SmsSend: 'smsSend',
                auth_SmsLogin: 'smsLogin',
                auth_SendForgotMessage: 'sendForgotMessage',
            }),
        };
    if (includeComputed)
        mixin.computed = {
            ...mapState('auth', {
                /**
                 * Возвращает значение, показывающее, является ли юзер гостем, или уже повошел в систему
                 * @returns {Boolean}
                 */
                auth_IsGuest: state => !state.isAuthorized,

                /**
                 * Имя юзера, вошедшего в систему
                 * @returns {String}
                 */
                auth_Name: state => state.name,

                /**
                 * Email юзера, вошедшего в систему
                 * @returns {String}
                 */
                auth_Email: state => state.email,

                /**
                 * ID юзера, вошедшего в систему
                 * @returns {any}
                 */
                auth_Id: state => state.id,

                /**
                 * Показывает необходимость залогиниться другим образом,
                 * если произошел вход через социальную сеть, которая не привязана в аккакунте
                 * @returns {Boolean}
                 */
                auth_NeesAnotherLogin: state => state.needAnotherLogin,

                auth_SocialDrivers: state => state.socialDrivers,

                auth_SuggestDriverss: state => state.suggestDrivers,
            }),
        };
    return mixin;
};
