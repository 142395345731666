<template>
    <div class="fake-vue-body">
        <layout-header />
        <slot />
        <modal-message />
        <layout-footer />
    </div>
</template>

<script>
import LayoutFooter from '../layout-footer/layout-footer.vue';
import LayoutHeader from '../layout-header/layout-header.vue';
import ModalMessage from '../modal-message/modal-message.vue';

export default {
    name: 'layout-main',
    components: {
        LayoutFooter,
        LayoutHeader,
        ModalMessage,
    },
};
</script>
