<template>
    <transition name="modal">
        <modal
            class="modal-login"
            v-if="modal_IsOpen"
            type="auth"
            @close="modal_ChangeState({ name: 'login', open: false })"
        >
            <form-login slot="body" class="modal-login__form" :autofocus="!isTabletLg" />
        </modal>
    </transition>
</template>

<script>
import Modal from '../controls/modal/modal.vue';
import FormLogin from '../form-login/form-login.vue';

import ModalMixin from '../../mixins/modal';
import './modal-login.css';

export default {
    name: 'modal-login',
    mixins: [ModalMixin('login')],
    components: {
        Modal,
        FormLogin,
    },
    computed: {
        isTabletLg() {
            return this.$mq.tabletLg;
        },
    },
    watch: {
        modal_IsOpen(value) {
            if (!value) this.$emit('closed');
        },
    },
};
</script>