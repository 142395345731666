var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon",
      class: ((_obj = {}),
      (_obj["icon--" + (_vm.modifier || _vm.name)] = _vm.modifier || _vm.name),
      _obj)
    },
    [_c("use", _vm._b({}, "use", { "xlink:href": "#icon-" + _vm.name }, false))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }