const initialState = {
    items: {
        filters_glossary: {
            open: false,
            state: {},
        },
        filters: {
            open: false,
            state: {},
        },
        claim: {
            open: false,
            state: {
                type: '',
                teacher_id: 0,
            },
        },
        review: {
            open: false,
            state: {},
        },
        discount_request: {
            open: false,
            state: {},
        },
        service_request: {
            open: false,
            state: {},
        },
        vacancy_request: {
            open: false,
            state: {},
        },
        vacancy_modal: {
            open: false,
            state: {
                vacancy: Object
            },
        },
        lennuf: {
            open: false,
            state: {},
        },
        phone_callback: {
            open: false,
            state: {},
        },
        ozon_popup: {
            open: false,
            state: {},
        },
        image_preview: {
            open: false,
            state: {},
        },
        message: {
            open: false,
            state: {
                message: '',
            },
        },
        modal_dialog: {
            open: false,
            state: {},
        },
        login: {
            open: false,
            state: {},
        },
        forgot_password: {
            open: false,
            state: {},
        },
        subscribe: {
            open: false,
            state: {},
        },
        check_to_email: {
            open: false,
            state: {},
        },
        check_to_post: {
            open: false,
            state: {},
        },
        promo: {
            open: false,
            state: {},
        },
        vebinar_register: {
            open: false,
            state: {},
        },
        sms_login: {
            open: false,
            state: {},
        },
        tinkoff_period: {
            open: false,
            state: {},
        },
    },
};

export default function(newState = {}) {
    return {
        namespaced: true,
        state: Object.assign(initialState, newState),
        mutations: {
            /**
             * Изменение состояния объекта стейта попапов
             * @param {*} state
             * @param {*} data
             */
            changeState(state, { name, ...modalState }) {
                const names = Object.keys(state.items);
                for (let i = 0; i < names.length; i++) state.items[names[i]].open = false;
                const modal = state.items[name];
                Object.assign(modal, modalState);
            },
        },
        actions: {
            /**
             * Метод изменения стейта попапа
             *
             * @param {*} payload
             */
            changeState({ commit }, payload) {
                commit('changeState', payload);
            },
        },
    };
}
