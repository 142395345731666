var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "breadcrumbs" },
    _vm._l(_vm.items, function(item, index) {
      return _c(
        "li",
        {
          key: index,
          staticClass: "breadcrumbs__item",
          class: { active: index === _vm.items.length - 1 }
        },
        [_c("a", { attrs: { href: item.url } }, [_vm._v(_vm._s(item.title))])]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }