var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "modal" } },
    [
      _vm.modal_IsOpen
        ? _c(
            "modal",
            {
              staticClass: "modal-form-subscribe",
              attrs: { type: "sm" },
              on: {
                close: function($event) {
                  return _vm.modal_ChangeState({
                    name: "subscribe",
                    open: false
                  })
                }
              }
            },
            [
              _c("v-svg", {
                staticClass: "modal-form-subscribe__header",
                attrs: { slot: "header", name: "news-subscribe" },
                slot: "header"
              }),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "form modal-form-subscribe__form",
                  attrs: { slot: "body", novalidate: "" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.sendRequest($event)
                    }
                  },
                  slot: "body"
                },
                [
                  _c(
                    "h3",
                    { staticClass: "form__hl modal-form-subscribe__hl" },
                    [_vm._v("Хочу получать новости")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-input",
                    {
                      staticClass: "form__col",
                      attrs: {
                        type: "email",
                        placeholder: "name@domain.com",
                        error: _vm.email_error,
                        "aria-required": "true"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "error",
                            fn: function(ref) {
                              var error = ref.error
                              return [
                                _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "slide-in-bottom",
                                      mode: "out-in"
                                    }
                                  },
                                  [
                                    error
                                      ? _c("div", { key: error }, [
                                          _vm._v(_vm._s(error))
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3034400235
                      ),
                      model: {
                        value: _vm.form.email,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "email", $$v)
                        },
                        expression: "form.email"
                      }
                    },
                    [
                      _vm._v("\n                E-mail"),
                      _c("span", { attrs: { "aria-hidden": "true" } }, [
                        _vm._v("*")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-input",
                    {
                      staticClass: "form__col",
                      attrs: {
                        placeholder: "Введите Имя",
                        error: _vm.name_error,
                        "aria-required": "true"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "error",
                            fn: function(ref) {
                              var error = ref.error
                              return [
                                _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "slide-in-bottom",
                                      mode: "out-in"
                                    }
                                  },
                                  [
                                    error
                                      ? _c("div", { key: error }, [
                                          _vm._v(_vm._s(error))
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3034400235
                      ),
                      model: {
                        value: _vm.form.name,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name"
                      }
                    },
                    [
                      _vm._v("\n                Имя и фамилия"),
                      _c("span", { attrs: { "aria-hidden": "true" } }, [
                        _vm._v("*")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "form__col modal-form-subscribe__recaptcha"
                    },
                    [
                      _c("vue-recaptcha", {
                        ref: "recaptcha",
                        staticClass: "modal-form-subscribe__recaptcha-widget",
                        class: { "is-invalid": _vm.recaptcha_error },
                        attrs: { sitekey: _vm.$store.state.env.recaptcha_key },
                        on: {
                          verify: _vm.onRecaptchaVerify,
                          expired: _vm.onRecaptchaExpired
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "modal-form-subscribe__recaptcha-error"
                        },
                        [
                          _c(
                            "transition",
                            {
                              attrs: { name: "slide-in-bottom", mode: "out-in" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.recaptcha_error,
                                      expression: "recaptcha_error"
                                    }
                                  ],
                                  staticClass:
                                    "error-message modal-form-subscribe__recaptcha-error-message",
                                  attrs: { role: "alert" }
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.recaptcha_error) +
                                      "\n                        "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form__cols modal-form-subscribe__submit" },
                    [
                      _c(
                        "v-link",
                        {
                          staticClass: "modal-form-subscribe__submit-link",
                          attrs: { tag: "button" },
                          on: {
                            click: function($event) {
                              return _vm.modal_ChangeState({
                                name: "subscribe",
                                open: false
                              })
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    Нет, спасибо\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-button",
                        {
                          staticClass: "form__submit-btn",
                          attrs: { type: "submit" }
                        },
                        [_vm._v("Подписаться")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }