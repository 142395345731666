import { mapState, mapActions, mapGetters } from 'vuex';

/**
 * Миксин для хэлпер методов, связанных с корзиной
 */
export default (includeMethods = true, includeComputed = true) => {
    const mixin = {};
    if (includeMethods === true)
        mixin.methods = {
            ...mapActions('cart', {
                cart_SetToCart: 'setToCart',
                cart_RemoveFromCart: 'removeFromCart',
                cart_ApplyPromocode: 'applyPromocode',
                cart_Clear: 'clear',
            }),
        };
    if (includeComputed === true)
        mixin.computed = {
            ...mapGetters('cart', {
                cart_InCart: 'inCart',
            }),
            ...mapState('cart', {
                cart_Items: 'items',
                cart_Discount: 'discount',
                cart_Price: 'price',
            }),
        };
    return mixin;
};
