import '../scripts/common';
import Vue from 'vue';
import store from './store/store';
import Helpers from '../scripts/helpers';
import Services from '../scripts/services/services';

import LayoutMain from './components/layout-main/layout-main.vue';
import breadcrumbs from './components/breadcrumbs/breadcrumbs.vue';

import VueGtag from 'vue-gtag';
import VueYandexMetrika from 'vue-yandex-metrika';

Vue.use(VueGtag, {
    config: {id: 'UA-210085691-1'}
});

Vue.use(VueYandexMetrika, {
    id: 44606269,
    env: process.env.NODE_ENV
});

Vue.component('layout-main', LayoutMain);
Vue.component('breadcrumbs', breadcrumbs);

import VueCookie from 'vue-cookie';
Vue.use(VueCookie);

Services.instance().register('store', () => {
    return store;
});

Vue.mixin({
    methods: {
        preparePrice(number, decimals, dec_point, thousands_sep) {
            return Helpers.preparePrice(number, decimals, dec_point, thousands_sep);
        },
        pluralForm(n, forms) {
            return Helpers.plural_form(n, forms);
        },
        strToList(str) {
            if (!str) {
                return [];
            }
            return String(str).split('\n');
        },
    },
});
