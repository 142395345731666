/* eslint-disable require-jsdoc */
import withQuery from 'with-query';

export default class NetService {
    loading = false;

    /**
     * @returns {Promise<Response>}
     */
    post(path, data) {
        return this.send('POST', path, data);
    }

    /**
     * @returns {Promise<Response>}
     */
    delete(path, data) {
        return this.send('DELETE', path, data);
    }

    /**
     * @returns {Promise<Response>}
     */
    get(path, data) {
        return this.send('GET', path, data);
    }

    /**
     * @returns {Promise<Response>}
     */
    send(method, path, data) {
        if(this.loading) {
            return Promise.reject('loading... request stoped...');
        }

        const headers = {
            Accept: 'application/json',
        };

        const params = {
            method,
            credentials: 'include',
        };

        let url = path;
        if (method === 'GET') {
            url = withQuery(url, data);
        } else if (data instanceof FormData) {
            headers.Accept = undefined;
            params.body = data;
        } else {
            headers['Content-Type'] = 'application/json';
            params.body = JSON.stringify(data);
        }

        // Csrf
        const token = document.head.querySelector('meta[name="csrf-token"]');
        headers['X-CSRF-TOKEN'] = token.content;

        params.headers = headers;

        this.loading = true;
        let context = this;

        return fetch(url, params)
            .then(resp => {
                context.loading = false;

                const json = resp.json(); // there's always a body
                if (resp.status >= 200 && resp.status < 300) {
                    return json;
                }
                return json.then(Promise.reject.bind(Promise));
            })
            .then(
                function(response) {
                    return response;
                },
                function(response) {
                    console.log(response.errors);
                    //alert('Что то пошло не так. Попробуйте обновить страницу и повторить запрос.');
                    return Promise.reject(response.errors);
                }
            );
    }
}
