var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "root", staticClass: "menu-header" },
    [
      _c("transition", { attrs: { name: "slide-absolute" } }, [
        _c(
          "div",
          { key: _vm.type },
          [
            _vm._t("top"),
            _vm._v(" "),
            _c(
              "nav",
              { staticClass: "menu-header__nav" },
              [
                _c("v-list", {
                  staticClass: "menu-header__nav-list",
                  attrs: {
                    "key-field": _vm.keyField,
                    items: _vm.items,
                    isHeaderMenu: true
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._t(
                              "item",
                              [
                                item.name && item.click !== undefined
                                  ? _c(
                                      "v-link",
                                      {
                                        staticClass:
                                          "link--black menu-header__nav-item",
                                        attrs: { tag: "button" },
                                        on: { click: item.click }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(item.name) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  : item.name && item.href !== "/"
                                  ? _c(
                                      "v-link",
                                      {
                                        staticClass:
                                          "link--black menu-header__nav-item",
                                        attrs: { href: item.href }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(item.name) +
                                            "\n                                "
                                        ),
                                        item.number !== undefined
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "menu-header__nav-item-number"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(item.number) +
                                                    "\n                                "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : item.name
                                  ? _c(
                                      "v-link",
                                      {
                                        staticClass:
                                          "link--grey menu-header__nav-item",
                                        attrs: {
                                          href: item.href,
                                          placement: "after",
                                          "icon-name": "line",
                                          "icon-modifier": "line-small"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(item.name) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              { item: item }
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ],
              1
            )
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }