<template>
    <div class="v-input">
        <label class="v-input__label" :for="inputId">
            <slot/>
        </label>
        <div class="v-input__container">
            <slot name="before"/>
            <input
                v-if="tag === 'input'"
                v-bind="$attrs"
                v-on="inputListeners"
                class="v-input__input"
                :class="{ 'is-invalid': error }"
                :id="inputId"
                :value="value"
                :type="type"
                :aria-describedby="`${inputId}-alert`"
            />
            <textarea
                v-if="tag === 'textarea'"
                v-bind="$attrs"
                v-on="inputListeners"
                class="v-input__input"
                :class="{ 'is-invalid': error }"
                :id="inputId"
                :value="value"
                :type="type"
                :aria-describedby="`${inputId}-alert`"
            />
            <template v-if="tag === 'file'">
                <v-button
                    class="modal-discount-request__submit-btn"
                    type="button"
                    @click="fileClick"
                >Выбрать</v-button>
                <div
                    class="v-input__file-name"
                >{{ fileName }}</div>
                <input
                    class="v-input__file-field"
                    type="file"
                    :id="inputId"
                    v-on="inputListeners"
                    @change="fileChange"
                >
            </template>
            <template v-if="tag === 'file_clip'">
                <div class="v-input__file-clip" @click="fileClick" >
                    <v-svg class="v-input__file-clip-icon" name="clip" />
                    <span class="v-input__file-clip-text">Прикрепить файл</span>
                </div>
                <input
                    class="v-input__file-field"
                    type="file"
                    :key="inputId"
                    v-on="inputListeners"
                    @change="fileChange"
                />
            </template>
            <slot name="after"/>
        </div>
        <div :id="`${inputId}-alert`" class="error-message v-input__error" role="alert">
            <slot name="error" :error="error">
                {{ error }}
            </slot>
        </div>
    </div>
</template>

<script>
import VSvg from '../../controls/VSvg/VSvg.vue';
import '../../../../images/sprite/clip.svg';
import inputMixin from './inputMixin';
import './VInput.css';
import VButton from "../VButton/VButton.vue";

const validTags = ['input', 'textarea', 'file', 'file_clip'];

export default {
    name: 'v-input',
    inheritAttrs: false,
    mixins: [inputMixin],
    components: {
        VButton,
        VSvg,
    },
    props: {
        value: {},
        type: {type: String, default: 'text'},
        tag: {
            type: String,
            default: 'input',
            validator(value) {
                return validTags.indexOf(value) !== -1;
            },
        },
    },
    data() {
        return {
            inputId: `v-input-id-${this._uid}`,
            fileName: 'Файл не выбран',
        };
    },
    watch: {
    },
    methods: {
        fileClick() {
            document.getElementById(this.inputId).click();
        },
        fileChange(e) {
            let fullPath = document.getElementById(this.inputId).value;

            if (fullPath) {
                var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
                var filename = fullPath.substring(startIndex);
                if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
                    filename = filename.substring(1);
                }

                this.fileName = filename;

                var files = e.target.files || e.dataTransfer.files;
                if (!files.length) return;
                this.$emit('input', files[0]);
            } else {
                this.fileName = 'Файл не выбран';
            }
        },
    }
};
</script>
