<template>

        <swiper :options="bannerOption">
            <swiper-slide v-for="(item, index) in banners" :key="index" :data-action="item.action">
                <a :href="getHref(item)">
                    <img class="header-main__banner-image" :src="item.image_home" alt="course banner" v-if="homePage && item.image_home">
                    <img class="header-main__banner-image" :src="item.image_mobile" alt="course banner" v-if="isMobile && !homePage">
                    <img class="header-main__banner-image" :src="item.image" alt="course banner" v-if="!isMobile && !homePage">
                </a>
            </swiper-slide>
        </swiper>

</template>
<script>
import 'swiper/dist/css/swiper.css';
import './courseBanner.css';
import {swiper, swiperSlide} from 'vue-awesome-swiper';
import MetricMixin from "../../../mixins/metric";

export default {
    name: 'course-banner',
    components: {
        swiper,
        swiperSlide
    },
    mixins: [MetricMixin],
    props: {
        banners: {
            type: Array,
            default() {
                return [];
            }
        },
        homePage: { type: Boolean, default: false}
    },
    data() {
        return {
            bannerOption: {
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                },
                allowTouchMove: false,
                effect: 'fade',
                loop: true,
                on: {
                    click: function () {
                        let curSlide = this.slides[ this.activeIndex ]
                        let action = curSlide.getAttribute('data-action');
                        if (action == 'open_link') {
                            this.$metrika.reachGoal('click_promo_header');
                            this.$gtag.event('promo_header', {'event_category': 'click'});
                        } else if (action == 'open_b24_1') {
                            document.getElementById('show-b24-feedback-popup').click();
                        } else if (action == 'open_b24_2') {
                            document.getElementById('show-b24-feedback-popup-2').click();
                        }
                    },
                },
            }
        }
    },
    computed: {
        isMobile() {
            return this.$mq.mobile;
        }
    },
    methods: {
        getHref(item) {
            return (item.action == 'open_link')? item.course_link : '#';
        },
    }
}
</script>
