<template>
    <div ref="root" class="menu-header">
        <transition name="slide-absolute">
            <div :key="type">
                <slot name="top" />
                <nav class="menu-header__nav">
                    <v-list class="menu-header__nav-list" :key-field="keyField" :items="items" :isHeaderMenu="true">
                        <template v-slot:item="{ item }">
                            <slot name="item" :item="item">
                                <v-link
                                    v-if="item.name && item.click !== undefined"
                                    class="link--black menu-header__nav-item"
                                    tag="button"
                                    @click="item.click"
                                >
                                    {{ item.name }}
                                </v-link>
                                <!--<v-link
                                    v-else-if="item.name == 'Версия для слабовидящих'"
                                    class="link--black menu-header__nav-item bvi-open"
                                    tag="button"
                                    aria-label="Версия для слабовидящих"
                                >
                                    {{ item.name }}
                                </v-link>-->
                                <v-link
                                    v-else-if="item.name && item.href !== '/'"
                                    class="link--black menu-header__nav-item"
                                    :href="item.href"
                                >
                                    {{ item.name }}
                                    <span v-if="item.number !== undefined" class="menu-header__nav-item-number">
                                        {{ item.number }}
                                    </span>
                                </v-link>
                                <v-link
                                    v-else-if="item.name"
                                    class="link--grey menu-header__nav-item"
                                    :href="item.href"
                                    placement="after"
                                    icon-name="line"
                                    icon-modifier="line-small"
                                >
                                    {{ item.name }}
                                </v-link>
                            </slot>
                        </template>
                    </v-list>
                </nav>
            </div>
        </transition>
    </div>
</template>

<script>
import VLink from '../controls/VLink/VLink.vue';
import VList from '../controls/VList/VList.vue';

import '../../../images/sprite/line.svg';
import './menu-header.css';

export default {
    name: 'menu-header',
    components: {
        VLink,
        VList,
    },
    props: {
        keyField: {
            type: String,
        },
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        type: {
            type: String,
            default: 'menu',
        },
    },
};
</script>