<template>
    <transition name="modal">
        <modal
            class="modal-forgot-password"
            v-if="modal_IsOpen"
            type="auth"
            @close="modal_ChangeState({ name: 'forgot_password', open: false })"
        >
            <form slot="body" class="form modal-forgot-password__form" @submit.prevent="sendForm" novalidate>
                <h3 class="form__hl">Забыли пароль?</h3>
                <p class="modal-forgot-password__text">
                    Введите e-mail, который вы указали при регистрации и мы вышлем вам инструкции по восстановлению
                    пароля.
                </p>
                <v-input
                    class="form__col"
                    v-model="form.email"
                    type="email"
                    placeholder="name@domain.com"
                    :error="email_error"
                    aria-required="true"
                >
                    E-mail<span>*</span>
                    <template v-slot:error="{ error }">
                        <div class="error-message v-input__error">
                            <transition name="slide-bottom">
                                <div v-show="error">{{ error }}</div>
                            </transition>
                        </div>
                    </template>
                </v-input>
                <div class="form__cols form__submit modal-forgot-password__submit">
                    <v-link
                        class="link--grey form__col modal-forgot-password__link"
                        icon-name="line"
                        icon-modifier="line-small"
                        @keydown.prevent
                        @click="modal_ChangeState({ name: 'login', open: true })"
                    >
                        Вернуться назад
                    </v-link>
                    <v-button class="form__col" type="submit">Отправить</v-button>
                </div>
                <div v-show="show_post_loader" class="post-loader">
                    <loader />
                </div>
            </form>
        </modal>
    </transition>
</template>

<script>
import VLink from '../controls/VLink/VLink.vue';
import VButton from '../controls/VButton/VButton.vue';
import VInput from '../controls/VInput/VInput.vue';
import Modal from '../controls/modal/modal.vue';
import Loader from '../loader/loader.vue';

import { validationMixin, required, email } from '../../../scripts/validation';
import ModalMixin from '../../mixins/modal.js';
import AuthMixin from '../../mixins/auth.js';
import '../../../images/sprite/line.svg';
import './modal-forgot-password.css';

export default {
    name: 'modal-forgot-password',
    components: {
        VButton,
        VLink,
        VInput,
        Modal,
        Loader,
    },
    mixins: [validationMixin, AuthMixin(true, false), ModalMixin('forgot_password')],
    data() {
        return {
            form: {
                email: '',
            },
            show_post_loader: false,
        };
    },
    validations: {
        form: {
            email: {
                required,
                email,
            },
        },
    },
    methods: {
        sendForm() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.show_post_loader = true;
                this.auth_SendForgotMessage(this.form).then(() => {
                    this.show_post_loader = false;
                    this.modal_ChangeState({
                        name: 'message',
                        open: true,
                        state: { message: 'Инструкции по восстановлению пароля высланы вам на почту.' },
                    });
                });
            }
        },
    },
    watch: {
        modal_IsOpen(value) {
            if (!value) {
                this.form.email = '';
                this.$v.$reset();
                this.$emit('closed');
            }
        },
    },
    computed: {
        email_error() {
            if (this.$v.form.email.$dirty) {
                if (!this.$v.form.email.required) return 'Обязательное поле';
                if (!this.$v.form.email.email) return 'Формат name@domain.com';
            }
        },
    },
};
</script>