import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('layout', {
            layout_StaticBlock: 'staticBlock',
            layout_MenuTop: 'menuTop',
            layout_MenuBottom: 'menuBottom',
            layout_ModeShop: 'modeShop',
            layout_ShowFooter: 'showFooter',
            layout_CourseBanners: 'courseBanners'
        }),
    },
};
