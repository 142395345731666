import Services from '../../../scripts/services/services';

const initialState = {
    id: 0,
    email: '',
    image: '',
    last_name: '',
    middle_name: '',
    name: '',
    phone: '',
    addresses: [],
    addresses_loaded: false,
};

export default function(newState = {}) {
    return {
        namespaced: true,
        state: Object.assign(initialState, newState),
        getters: {
            fullName: state => `${state.name} ${state.last_name}`,
        },
        mutations: {
            updateUserInfo(state, payload) {
                Object.assign(state, payload);
            },

            /**
             * Изменения адресов юзера
             * @param {*} state
             * @param {*} list
             */
            updateUserAddress(state, list) {
                state.addresses = list;
                state.addresses_loaded = true;
            },
        },
        actions: {
            /**
             * Подписка на новости
             * @returns {Promise} Promise
             */
            subscribe(context, payload = {}) {
                let ymClientID; ym(44606269, 'getClientID', function(clientID) { ymClientID = clientID;} );
                payload.ymClientID = ymClientID;
                let obj = {
                    name: context.state.name + ' ' + context.state.last_name,
                    phone: context.state.phone,
                    email: context.state.email,
                    message: 'Подписка на новости',
                }
                console.log(obj);
                Comagic.addOfflineRequest(obj);
                return Services.net().post('/ajax/subscribe', payload);
            },

            /**
             * Метод сохранения информации о юзере
             * @returns {Promise} Promise
             */
            saveUserInfo({ commit }, payload = {}) {
                return Services.net()
                    .post('/ajax/profile/settings/saveUser', payload)
                    .then(data => {
                        commit('updateUserInfo', data.user);
                        return data;
                    });
            },

            /**
             * Метод обновления информации о юзере
             * @returns {Promise} Promise
             */
            updateUserInfo({ commit }) {
                return Services.net()
                    .get('/ajax/user-info')
                    .then(data => {
                        commit('updateUserInfo', data.user);
                        return data;
                    });
            },

            /**
             * Получение адреса юзера
             * @returns {Promise}
             */
            updateUserAddress({ commit, state }) {
                return new Promise(resolve => {
                    if (state.addresses_loaded) resolve();
                    else
                        Services.net()
                            .get('/ajax/addresses')
                            .then(data => {
                                commit('updateUserAddress', data.addresses);
                                resolve();
                            });
                });
            },
        },
    };
}
