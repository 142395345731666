<template>
    <ul class="breadcrumbs">
        <li
            v-for="(item, index) in items"
            class="breadcrumbs__item"
            :key="index"
            :class="{ active: index === items.length - 1 }"
        >
            <a :href="item.url">{{ item.title }}</a>
        </li>
    </ul>
</template>

<script>
import './breadcrumbs.css';
import { mapState } from 'vuex';

export default {
    name: 'breadcrumbs',
    computed: {
        ...mapState('layout', {
            items: 'breadcrumbs',
        }),
    },
};
</script>
