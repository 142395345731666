<template>
    <header ref="header" class="header-main">

        <div class="header-main__banner-top">
            <!-- <a class="header-main__banner-top link" href="/courses/onlayn-klass-e-commerce-360">
                <div class="header-main__banner-text">
                    В поддержку всемирного дистанционного образования:
                    <br />
                    <span class="header-main__banner-blue">СКИДКА -20%</span>
                    <strong>на курс</strong>
                    <span class="header-main__banner-blue">E-COMMERCE 360˚</span>
                </div>
                <div class="header-main__banner-image">
                    <img class="header-main__banner-image" src="../../../images/inhtml/go.png" />
                </div>
            </a> -->
            <div class="header-main__banner-image-wrapper course-banner">
                <CourseBanner :banners="layout_CourseBanners"></CourseBanner>
            </div>
             <!-- <div class="header-main__banner-image" @click="
                                            modal_ChangeState({
                                                name: 'claim',
                                                open: true,
                                                state: { type: 'banner' },
                                            })
                                        ">
                <img class="header-main__banner-image" src="../../../images/inhtml/smartfon.png" alt="logo">
             </div> -->
        </div>

        <div
            class="header-main__sticky-container"
            :class="{ 'header-main__sticky-container--sticky': isSticky }"
        >
            <div class="header-main__top">
                <div class="container header-main__container">
                    <!--<div class="header-main__item header-main__item-city">
                        <div class="text-sm header-main__item-city-label">Ваш город:</div>
                        <button
                            class="link link--black header-main__item-city-value"
                            @click="isCitySelectionPopupOpen = true"
                        >
                            {{ selectedCity }}
                            <svg class="icon icon--arrow-down header-main__item-city-icon">
                                <use xlink:href="#icon-arrow-down"></use>
                            </svg>
                        </button>
                    </div>-->
                    <div class="header-main__banner">
                        <!-- <a class="header-main__banner link" href="/courses/onlayn-klass-e-commerce-360">
                            <div class="header-main__banner-text">
                                В поддержку всемирного дистанционного образования:
                                <br />
                                <span class="header-main__banner-blue">СКИДКА -20%</span>
                                <strong>на курс</strong>
                                <span class="header-main__banner-blue">E-COMMERCE 360˚</span>
                            </div>
                            <div class="header-main__banner-image">
                                <img
                                    class="header-main__banner-image"
                                    src="../../../images/inhtml/go.png"
                                />
                            </div>
                        </a> -->
                        <!-- <div class="header-main__banner-image" @click="
                                            modal_ChangeState({
                                                name: 'claim',
                                                open: true,
                                                state: { type: 'banner' },
                                            })
                                        ">
                            <a href="/courses/onlayn-klass-e-commerce-360">
                                <img class="header-main__banner-image" src="../../../images/inhtml/banner_1024x48.png">
                            </a>
                        </div> -->
                        <div class="header-main__banner-image-wrapper course-banner">
                            <CourseBanner :banners="layout_CourseBanners"></CourseBanner>
                        </div>
                    </div>

                    <div class="header-main__right-block">
                        <ul class="header-main__socials">
                            <li :key="icon.name" v-for="icon in socialIcons">
                                <a :href="icon.url" target="_blank" :aria-label="icon.name" @click="sendMetric('click_social', 'click', 'social')">
                                    <v-svg :name="icon.icon"></v-svg>
                                </a>
                            </li>
                        </ul>

                        <v-link
                            class="link--black header-main__item header-main__item-phone comagic_phone"
                            :href="mainPhoneHref"
                            :icon-name="isTablet ? 'call' : null"
                            :aria-label="layout_StaticBlock.main_phone"
                            @click="showPhoneCallback(); sendMetric('click_phone', 'click', 'phone')"
                        >
                            <span
                                class="header-main__item-phone-text"
                            >{{ layout_StaticBlock.main_phone }}</span>
                        </v-link>

                        <!--<v-link
                            class="link--black header-main__item header-main__item-wa"
                            :href="socialIcons[0].url"
                            :icon-name="isTablet ? socialIcons[0].icon : null"
                            :aria-label="socialIcons[0].name"
                            @click="sendMetric('click_social', 'click', 'social')"
                        >
                        </v-link>-->

                        <div class="header-main__search">
                            <v-input
                                class="header-main__search-input"
                                v-model="search_text"
                                v-show="show_search"
                                placeholder="Поиск..."
                                aria-label="Поиск"
                                @keyup.enter="onStartSearch"
                            >
                                <template v-slot:before>
                                    <v-button
                                        class="header-main__search-btn-search"
                                        iconName="search"
                                        @click="onStartSearch"
                                    />
                                </template>
                                <template v-slot:after>
                                    <v-button
                                        class="header-main__search-btn-close"
                                        iconName="cross"
                                        @click="onCloseSearch"
                                    />
                                </template>
                            </v-input>
                            <v-button
                                class="header-main__search-btn-show"
                                iconName="search"
                                @click="onShowSearch"
                            />
                        </div>

                        <div class="header-main__item header-main__item-menu">
                            <v-link
                                tag="button"
                                class="link--black header-main__item"
                                @click.stop="onCabinetBtnClick"
                                @touchstart.stop
                                :icon-name="cabinetIconName"
                                :aria-label="auth_IsGuest ? 'Войти' : user_FullName"
                            >
                                <v-clamp
                                    :class="{ 'header-main__item-menu-login': auth_IsGuest }"
                                    v-show="!isTablet"
                                    :maxLines="1"
                                    autoresize
                                >{{ auth_IsGuest ? 'Войти' : user_FullName }}</v-clamp>
                            </v-link>
                            <transition name="fade">
                                <menu-header
                                    ref="menu"
                                    class="header-main__popup-menu"
                                    v-if="isCabinetOpen && !auth_IsGuest && !isTabletLg"
                                    key-field="name"
                                    type="cabinet"
                                    :items="cabinetLinks"
                                />
                            </transition>
                        </div>
                        <v-link
                            tag="button"
                            class="link--black header-main__item bvi-open"
                            icon-name="eye-2"
                            placement="before"
                            title="Версия для слабовидящих"
                            aria-label="Версия для слабовидящих"
                        ></v-link>
                        <!--<v-link
                            v-if="!auth_IsGuest && !isTabletLg"
                            class="link--black header-main__item header-main__item-wishlist"
                            href="/profile/favorite"
                            title="Избранное"
                            aria-label="Избранное"
                        >
                            <div class="header-main__item-wishlist-inner">
                                <v-svg name="wishlist" />
                                <span
                                    v-if="wishlist_Items.length > 0"
                                    class="header-main__item-wishlist-count"
                                >{{ wishlist_Items.length }}</span>
                            </div>
                        </v-link>-->
                        <v-link
                            class="link--black header-main__item header-main__item-cart"
                            href="/cart"
                            title="Корзина"
                            aria-label="Корзина"
                        >
                            <div class="header-main__item-cart-inner">
                                <v-svg name="cart" />
                                <span
                                    v-if="cart_Items.length > 0"
                                    class="header-main__item-cart-count"
                                >{{ cart_Items.length }}</span>
                            </div>

                            <div v-if="cart_Items.length > 0" class="header-main__item-cart-price">
                                {{ preparePrice(cart_Price) }}
                                <span>&#x20bd;</span>
                            </div>
                        </v-link>
                    </div>
                </div>
            </div>
            <div class="header-main__panel">
                <div class="container header-main__container">
                    <burger
                        class="header-main__item header-main__item-burger"
                        :isOpen.sync="isMenuOpen"
                        aria-label="Открыть меню навигации"
                    />
                    <v-link
                        class="header-main__item header-main__item-logo"
                        href="/"
                        icon-name="logo"
                    />
                    <nav class="header-main__item-nav">
                        <v-list
                            class="header-main__item-nav-list"
                            key-field="name"
                            :items="layout_MenuTop | noFalse"
                            :isHeaderMenu="true"
                        >
                            <template v-slot:item="{ item: link }">
                                <v-link
                                    :class="checkUrl(link.href) ? 'link--black header-main__item-nav-item active' : 'link--black header-main__item-nav-item'"
                                    :href="link.href"
                                >{{ link.name }}
                                </v-link>
                            </template>
                        </v-list>
                    </nav>
                </div>
            </div>
            <transition name="slide-top">
                <menu-header
                    class="header-main__popup-menu"
                    v-if="(isMenuOpen || (isCabinetOpen && !auth_IsGuest)) && isTabletLg"
                    v-scroll-lock="(isMenuOpen || (isCabinetOpen && !auth_IsGuest)) && isTabletLg"
                    key-field="name"
                    :type="isCabinetOpen ? 'cabinet' : 'menu'"
                    :items="isCabinetOpen ? cabinetLinks : layout_MenuTop"
                >
                    <template v-slot:top="{ itemClass }">
                        <!-- <button
                            v-if="isMenuOpen"
                            class="header-main__item header-main__item-city"
                            @click="isCitySelectionPopupOpen = true"
                        >
                            <div class="text-sm header-main__item-city-label">
                                Ваш город:
                                <span class="header-main__item-city-value">{{ selectedCity }}</span>
                            </div>
                            <svg class="icon icon--line-small header-main__item-city-icon">
                                <use xlink:href="#icon-line"></use>
                            </svg>
                        </button>-->
                        <v-clamp
                            tag="div"
                            v-if="isCabinetOpen"
                            class="menu-header__nav-item header-main__popup-menu-name"
                            autoresize
                            :maxLines="1"
                        >{{ user_FullName }}</v-clamp>
                    </template>
                </menu-header>
            </transition>
            <!--
            <transition mode="out-in" name="modal">-->
            <!--                <modal v-if="isCitySelectionPopupOpen"-->
            <!--                       :close="() => isCitySelectionPopupOpen = false"-->
            <!--                       type="city-selection"-->
            <!--                >-->
            <!--                    <div slot="body" class="popup-city-selection">-->
            <!--                        <h3 class="popup-city-selection__title">Выберите город</h3>-->
            <!--                        <div class="popup-city-selection__search">-->
            <!--                            <input class="popup-city-selection__search-input"-->
            <!--                                   placeholder="Поиск"-->
            <!--                                   :value.sync="searchString"-->
            <!--                            >-->
            <!--                            <button class="popup-city-selection__search-btn">-->
            <!--                                <svg class="icon icon&#45;&#45;search">-->
            <!--                                    <use xlink:href="#icon-search"></use>-->
            <!--                                </svg>-->
            <!--                            </button>-->
            <!--                        </div>-->
            <!--                        <div class="header-main__popup-menu" v-scroll-lock="isCitySelectionPopupOpen">-->
            <!--                            <ul class="header-main__popup-menu-list">-->
            <!--                                <li v-for="city in cities" :key="city.name" class="header-main__popup-menu-item">-->
            <!--                                    <button class="link link&#45;&#45;black header-main__item"-->
            <!--                                            @click="() => { selectedCity = city.name; isCitySelectionPopupOpen = false }"-->
            <!--                                    >-->
            <!--                                        {{city.name}}-->
            <!--                                    </button>-->
            <!--                                </li>-->
            <!--                            </ul>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </modal>-->
            <!--            </transition>-->
        </div>
        <modal-login @closed="isCabinetOpen = false" />
        <modal-sms-login @closed="isCabinetOpen = false" />
        <modal-forgot-password @closed="isCabinetOpen = false" />
        <modal-phone-callback  v-on:showPostLoader="show_post_loader = $event" />
        <div v-show="show_post_loader" class="post-loader">
            <loader />
        </div>
    </header>
</template>

<script>
import VClamp from 'vue-clamp';
import VSvg from '../controls/VSvg/VSvg.vue';
import VLink from '../controls/VLink/VLink.vue';
import VButton from '../../components/controls/VButton/VButton.vue';
import VInput from '../../components/controls/VInput/VInput.vue';
import VList from '../controls/VList/VList.vue';
import Burger from '../controls/burger/burger.vue';
import Modal from '../controls/modal/modal.vue';
import Loader from '../../components/loader/loader.vue';
import CourseBanner from '../controls/courseBanner/courseBanner.vue';

import MenuHeader from '../menu-header/menu-header.vue';
import ModalLogin from '../modal-login/modal-login.vue';
import ModalSmsLogin from '../modal-sms-login/modal-sms-login.vue';
import ModalForgotPassword from '../modal-forgot-password/modal-forgot-password.vue';
import ModalPhoneCallback from '../../components/modal-phone-callback/modal-phone-callback.vue';

import Helpers from '../../../scripts/helpers.js';
import ModalMixin from '../../mixins/modal';
import AuthMixin from '../../mixins/auth';
import CartMixin from '../../mixins/cart';
import WishlistMixin from '../../mixins/wishlist';
import LayoutMixin from '../../mixins/layout';
import MetricMixin from '../../mixins/metric';

import bvi from 'bvi';
import { mapGetters } from 'vuex';
import '../../../images/sprite/eye-2.svg';
import '../../../images/sprite/call.svg';
import '../../../images/sprite/cart.svg';
import '../../../images/sprite/wishlist.svg';
import '../../../images/sprite/line.svg';
import '../../../images/sprite/cross.svg';
import '../../../images/sprite/user.svg';
import '../../../images/sprite/cabinet.svg';
import '../../../images/sprite/arrow-down.svg';
import '../../../images/sprite/logo.svg';
import '../../../images/sprite/search.svg';
import './layout-header.css';

export default {
    name: 'layout-header',
    mixins: [LayoutMixin, ModalMixin(), AuthMixin(), CartMixin(false), WishlistMixin(false), MetricMixin],
    components: {
        VClamp,
        VSvg,
        VLink,
        VButton,
        VInput,
        VList,
        Burger,
        Modal,
        Loader,
        ModalPhoneCallback,

        MenuHeader,
        ModalLogin,
        ModalSmsLogin,
        ModalForgotPassword,
        CourseBanner
    },
    //data
    data() {
        return {
            show_post_loader: false,
            show_search: false,
            search_text: '',
            isCabinetOpen: false,
            isMenuOpen: false,
            isSticky: false,
            // isCitySelectionPopupOpen: false,
            // searchString: '',
            // selectedCity: 'Нижний новгород',
            // cities: [
            //     {name: 'Москва'},
            //     {name: 'Зеленоград'},
            //     {name: 'Новосибирск'},
            //     {name: 'Мурманск'},
            //     {name: 'Владивосток'},
            //     {name: 'Челябинск'},
            //     {name: 'Белгород'},
            // ],

            socialIcons: [
                {
                    name: 'WhatsApp',
                    icon: 'wa-black',
                    url: ' https://wa.me/74951280003',
                },
                /*{
                    name: 'Facebook',
                    icon: 'fb-black',
                    url: 'https://www.facebook.com/iwengo.ru/',
                },
                {
                    name: 'Youtube',
                    icon: 'yb-black',
                    url: 'https://www.youtube.com/channel/UCfnfRUGH_CuFabZpsrSgmWg/featured',
                },
                {
                    name: 'Instagram',
                    icon: 'ig-black',
                    url: 'https://www.instagram.com/iwengo.ru/',
                },
                {
                    name: 'Telegram',
                    icon: 'telegram',
                    url: 'https://t.me/goecommerce',
                },*/
            ],
        };
    },
    computed: {
        ...mapGetters('user', {
            user_FullName: 'fullName',
        }),
        cabinetIconName() {
            if (this.isTablet) return this.isCabinetOpen ? 'cross' : 'user';
            return this.auth_IsGuest ? null : 'cabinet';
        },
        cabinetLinks() {
            return [
                { href: '/profile', name: 'Профиль' },
                { href: '/profile/orders', name: 'История заказов' },
                { href: '/profile/favorite', name: 'Избранное', number: this.wishlist_Items.length },
                { href: '/profile/courses', name: 'Мои курсы' },
                { href: '/profile/documents', name: 'Мои документы' },
                { href: '/profile/services', name: 'Мои услуги' },
                { href: '/faqs', name: 'FAQ' },
                // { url: '/profile/reviews', name: 'Мои отзывы' },
                { href: '/profile/settings', name: 'Настройки профиля' },
                { click: this.auth_Logout, name: 'Выход' },
            ];
        },
        mainPhoneHref() {
            return 'tel:+' + Helpers.rawPhone(this.layout_StaticBlock.main_phone);
        },
        isTabletLg() {
            return this.$mq.tabletLg;
        },
        isTablet() {
            return this.$mq.tablet;
        },
    },

    filters: {
        noFalse (arr) {
            return arr.filter(el => el && el.href)
        }
    },
    watch: {
        isMenuOpen(value) {
            if (value) this.isCabinetOpen = false;
        },
        isCabinetOpen(value) {
            if (value) {
                this.isMenuOpen = false;
                if (!this.isTabletLg) {
                    document.addEventListener('click', this.checkHide, false);
                    document.addEventListener('touchstart', this.checkHide, false);
                }
            } else {
                document.removeEventListener('click', this.checkHide);
                document.removeEventListener('touchstart', this.checkHide);
            }
        },
    },

    methods: {
        onPageScroll() {
            this.isSticky = window.pageYOffset > 0;
        },
        onCabinetBtnClick() {
            this.isCabinetOpen = !this.isCabinetOpen;
            if (this.isCabinetOpen && this.auth_IsGuest) this.modal_ChangeState({ name: 'login', open: true });
        },
        checkHide(e) {
            const { menu } = this.$refs;
            if (!menu || e.target === menu.$el || menu.$el.contains(e.target)) return;
            e.stopPropagation();
            this.isCabinetOpen = false;
        },
        checkUrl(item) {
            if(window.location.href == item) {
                return true
            } else {
                return false
            }
        },
        clickBanner () {
            this.$metrika.reachGoal('click_promo_header');
            this.$gtag.event('promo_header', { 'event_category' : 'click' });
        },
        onShowSearch () {
            this.show_search = true;
        },
        onCloseSearch () {
            this.show_search = false;
        },
        onStartSearch () {
            if (this.search_text == '') return;
            window.location.href = '/search?text=' + this.search_text;
        },
        showPhoneCallback() {
            // наш режим работы пн-пт с 10 до 19, в нерабочее время показываем попап
            let today = new Date();
            let day = today.getUTCDay();
            console.log('day: ' + day)
            if (day === 0 || day === 6) {
                this.modal_ChangeState({ name: 'phone_callback', open: true });
                return false;
            } else {
                let hour = today.getUTCHours();
                console.log('hour: ' + hour)
                if (hour < 7 || hour >= 16) {
                    this.modal_ChangeState({ name: 'phone_callback', open: true });
                    return false;
                }
            }
        }
    },
    mounted() {
        document.addEventListener('scroll', this.onPageScroll);
        new bvi.Bvi(); // версия сайта для слабовидящих
        // стили не подцепились, подключил их в app.blade.php + картинки при установке модуля не подтянулись
    },

    beforeDestroy() {
        document.removeEventListener('scroll', this.onPageScroll);
        document.removeEventListener('click', this.checkHide);
        document.removeEventListener('touchstart', this.checkHide);
    },
};
</script>
