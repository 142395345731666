var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "v-input" }, [
    _c(
      "label",
      { staticClass: "v-input__label", attrs: { for: _vm.inputId } },
      [_vm._t("default")],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "v-input__container" },
      [
        _vm._t("before"),
        _vm._v(" "),
        _vm.tag === "input"
          ? _c(
              "input",
              _vm._g(
                _vm._b(
                  {
                    staticClass: "v-input__input",
                    class: { "is-invalid": _vm.error },
                    attrs: {
                      id: _vm.inputId,
                      type: _vm.type,
                      "aria-describedby": _vm.inputId + "-alert"
                    },
                    domProps: { value: _vm.value }
                  },
                  "input",
                  _vm.$attrs,
                  false
                ),
                _vm.inputListeners
              )
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.tag === "textarea"
          ? _c(
              "textarea",
              _vm._g(
                _vm._b(
                  {
                    staticClass: "v-input__input",
                    class: { "is-invalid": _vm.error },
                    attrs: {
                      id: _vm.inputId,
                      type: _vm.type,
                      "aria-describedby": _vm.inputId + "-alert"
                    },
                    domProps: { value: _vm.value }
                  },
                  "textarea",
                  _vm.$attrs,
                  false
                ),
                _vm.inputListeners
              )
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.tag === "file"
          ? [
              _c(
                "v-button",
                {
                  staticClass: "modal-discount-request__submit-btn",
                  attrs: { type: "button" },
                  on: { click: _vm.fileClick }
                },
                [_vm._v("Выбрать")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "v-input__file-name" }, [
                _vm._v(_vm._s(_vm.fileName))
              ]),
              _vm._v(" "),
              _c(
                "input",
                _vm._g(
                  {
                    staticClass: "v-input__file-field",
                    attrs: { type: "file", id: _vm.inputId },
                    on: { change: _vm.fileChange }
                  },
                  _vm.inputListeners
                )
              )
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.tag === "file_clip"
          ? [
              _c(
                "div",
                {
                  staticClass: "v-input__file-clip",
                  on: { click: _vm.fileClick }
                },
                [
                  _c("v-svg", {
                    staticClass: "v-input__file-clip-icon",
                    attrs: { name: "clip" }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "v-input__file-clip-text" }, [
                    _vm._v("Прикрепить файл")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "input",
                _vm._g(
                  {
                    key: _vm.inputId,
                    staticClass: "v-input__file-field",
                    attrs: { type: "file" },
                    on: { change: _vm.fileChange }
                  },
                  _vm.inputListeners
                )
              )
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm._t("after")
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "error-message v-input__error",
        attrs: { id: _vm.inputId + "-alert", role: "alert" }
      },
      [
        _vm._t(
          "error",
          [_vm._v("\n            " + _vm._s(_vm.error) + "\n        ")],
          { error: _vm.error }
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }