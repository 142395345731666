import Services from './services/services';
import { TYPE_DOCUMENT, TYPE_COURSE, TYPE_SERVICE, TYPE_CONSTRUCT, TYPE_COURSE_TARIFF } from './cart-item';

/**
 * Статический класс с методами Api для корзины
 */
export default class Cart {
    /**
     * Возвращает параметры объекта корзины
     * @param {String} type
     * @param {*} itemId
     * @returns {Object} Params
     */
    static cartBaseParams(type, itemId) {
        const params = {};
        let key = '';

        switch (type) {
            case TYPE_DOCUMENT:
                key = 'document_id';
                break;
            case TYPE_SERVICE:
                key = 'service_id';
                break;
            case TYPE_CONSTRUCT:
                key = 'construct_id';
                break;
            case TYPE_COURSE_TARIFF:
                key = 'course_tariff_id';
                break;
            default:
                key = 'course_span_id';
                break;
        }

        params[key] = itemId;
        return params;
    }

    /**
     * Добавить продукт в корзину
     * @param {*} params
     */
    static set(params) {
        return Services.net().get('/ajax/cart/set', params);
    }

    /**
     * Удалить продукт из корзины
     * @param {*} params
     */
    static remove(params) {
        return Services.net().get('/ajax/cart/remove', params);
    }

    /**
     * Очистить корзину
     */
    static clear() {
        return Services.net().get('/ajax/cart/clear');
    }

    /**
     * Применение промокода
     * @param {String} code
     */
    static applyPromoCode(code) {
        return Services.net().post('/ajax/cart/apply-promo-code', { code });
    }
}
