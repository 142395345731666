<template>
    <form class="form form-login" novalidate>
        <div class="form-login__container form-login__header">
            <h3 class="form__hl form-login__hl">Вход через SMS</h3>
            <v-link class="form-login__register" href="/profile/register">Регистрация</v-link>
        </div>
        <div class="form-login__container">
            <v-input
                class="form__col"
                type="tel"
                v-model="phone"
                v-mask="'+7(###) ###-##-##'"
                placeholder="+7(___) ___-___-__"
                :error="phoneError"
                aria-required="true"
            >
                Телефон
                <span aria-hidden="true">*</span>
                <template v-slot:error="{ error }">
                    <transition name="slide-in-bottom" mode="out-in">
                        <div :key="error" v-if="error">{{ error }}</div>
                    </transition>
                </template>
            </v-input>
            <v-input
                class="form__col"
                v-model="smsCode"
                placeholder="Введите смс-код"
                :error="smsCodeError"
                aria-required="true"
                v-if="isSmsLogin"
            >
                Смс-код
                <span aria-hidden="true">*</span>
                <template v-slot:error="{ error }">
                    <transition name="slide-in-bottom" mode="out-in">
                        <div :key="error" v-if="error">{{ error }}</div>
                    </transition>
                </template>
            </v-input>
            <div class="form__cols form__submit form-login__submit">
                <v-button class="form-login__submit-btn" type="submit" v-if="!isSmsLogin" @click.prevent="smsSend()">Отправить</v-button>
                <v-button class="form-login__submit-btn" type="submit" v-else @click.prevent="smsLogin()">Войти</v-button>
                <v-button class="form-login__submit-btn" type="submit" v-if="isSmsLogin && !smsNext" @click.prevent="smsSend()">Еще раз</v-button>
            </div>
            <div v-if="smsNext" class="error-message form__col form-login__error">
                Повторно смс-код можно будет отправить через {{ smsNext }} сек.
            </div>
            <div class="error-message form__col form-login__error">
                <transition name="slide-in-bottom" mode="out-in" appear>
                    <div :key="error" role="alert" v-if="error">
                        {{ error }}
                    </div>
                </transition>
            </div>
        </div>
    </form>
</template>

<script>
import VButton from '../controls/VButton/VButton.vue';
import VList from '../controls/VList/VList.vue';
import VLink from '../controls/VLink/VLink.vue';
import VPassword from '../controls/VPassword/VPassword.vue';
import VInput from '../controls/VInput/VInput.vue';
import Modal from '../controls/modal/modal.vue';

import AuthMixin from '../../mixins/auth.js';
import SocialMixin from '../../mixins/social.js';
import ModalMixin from '../../mixins/modal';
import {validationMixin, email, password, required, minLength, tel} from '../../../scripts/validation';

import './form-sms-login.css';

import { mapState, mapActions } from 'vuex';

export default {
    name: 'form-sms-login',
    mixins: [validationMixin, SocialMixin, AuthMixin(), ModalMixin('sms_login')],
    props: {
        autofocus: {
            type: Boolean,
        },
    },
    components: {
        VButton,
        VLink,
        VInput,
        VPassword,
        VList,
        Modal,
    },
    validations: {
        phone: {
            required,
            tel,
        },
    },
    data() {
        return {
            smsNext: 0,
            smsNextTimer: 0,
            smsCode: '',
            phone: '',
            isSmsLogin: false,
            remember: false,
            error: this.auth_NeedAnotherLogin
                ? 'Вы пытаетесь войти в систему через социальную сеть, которая не прикреплена к аккаунту. Войдите в аккаунт через соцсети, указанные ниже или обычным образом, и прикрепите социальную сеть в настройках профиля'
                : '',
        };
    },
    computed: {
        isTabletLg() {
            return this.$mq.tabletLg;
        },
        acceptDrivers() {
            const good_drivers = new Set(this.auth_SuggestDrivers);
            return this.auth_SocialDrivers.filter(d => good_drivers.has(d.name));
        },
        socials() {
            return this.auth_NeedAnotherLogin ? this.acceptDrivers : this.auth_SocialDrivers;
        },
        phoneError() {
            if (this.$v.phone.$dirty) {
                if (!this.$v.phone.required) return 'Обязательное поле';
                if (!this.$v.phone.tel) return 'Формат +7(000) 00-00-00';
            }
        },
        smsCodeError() {

        },
    },
    methods: {
        setSmsNextTimer() {
            this.error = '';
            this.smsNext = 60;
            this.smsNextTimer = setInterval(() => {
                if (this.smsNext > 0) {
                    --this.smsNext;
                } else {
                    clearInterval(this.smsNextTimer)
                }
            }, 1000);
        },
        smsSend() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.auth_SmsSend({
                    phone: this.phone,
                }).then(data => {
                    if (data.status !== 'ok') {
                        this.error = data.error;
                    } else {
                        this.isSmsLogin = true;
                        this.setSmsNextTimer();
                    }
                });
            }
        },
        smsLogin() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.auth_SmsLogin({
                    phone: this.phone,
                    code: this.smsCode,
                }).then(data => {
                    if (data.status !== 'ok') {
                        this.error = data.error;
                    } else {
                        if(location.pathname.startsWith('/profile')) {
                            location.href = '/profile';
                        } else {
                            location.reload();
                        }
                    }
                });
            }
        }
    },
    watch: {
        modal_IsOpen(value) {
            if (!value) {
                this.email = '';
                this.password = '';
                this.error = '';
                this.$v.$reset();
            }
        },
    },
};
</script>
