var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-mask", on: { click: _vm.onClose } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "scroll-lock",
            rawName: "v-scroll-lock",
            value: _vm.lock,
            expression: "lock"
          }
        ],
        ref: "wrapper",
        staticClass: "modal-wrapper",
        class: { "modal-wrapper--fullscreen": _vm.type === "fullscreen" },
        attrs: { tabindex: "0" },
        on: { keydown: _vm.keyDown }
      },
      [
        _c(
          "div",
          {
            ref: "popup",
            staticClass: "modal-container popup",
            class: ((_obj = {}),
            (_obj["popup--" + _vm.type] = _vm.type),
            (_obj["popup--" + _vm.name] = _vm.name),
            _obj),
            on: { mousedown: _vm.onPopupMouseDown, mouseup: _vm.onPopupMouseUp }
          },
          [
            _c(
              "div",
              { staticClass: "modal-header popup__header" },
              [_vm._t("header")],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body popup__body" },
              [_vm._t("body")],
              2
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "modal-default-button popup__close",
                attrs: { type: "button", title: "Закрыть" },
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [
                _c("svg", { staticClass: "icon icon--cross-big" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-cross" } })
                ])
              ]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }