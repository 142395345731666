var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.name
    ? _c(
        "transition-group",
        {
          staticClass: "v-list",
          attrs: { tag: _vm.tag, name: _vm.name, appear: _vm.appear }
        },
        _vm._l(_vm.items, function(item, index) {
          return _c(
            "li",
            { key: item[_vm.keyField] || index, staticClass: "v-list__item" },
            [_vm._t("item", null, { item: item, index: index })],
            2
          )
        }),
        0
      )
    : _vm.tag === "ul" && _vm.isHeaderMenu
    ? _c(
        "ul",
        { staticClass: "v-list" },
        _vm._l(_vm.items, function(item, index) {
          return _c(
            "li",
            {
              key: item[_vm.keyField] || index,
              staticClass: "v-list__item",
              class: item.name === "О нас" ? "header-main__topmenu" : ""
            },
            [
              _vm._t("item", null, { item: item, index: index }),
              _vm._v(" "),
              item.name === "О нас"
                ? _c("ul", { staticClass: "header-main__submenu" }, [
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass:
                            "link link--black header-main__item-nav-item",
                          attrs: { href: "/reviews" }
                        },
                        [_vm._v("Отзывы")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass:
                            "link link--black header-main__item-nav-item",
                          attrs: { href: "/students" }
                        },
                        [_vm._v("Студенты")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass:
                            "link link--black header-main__item-nav-item",
                          attrs: { href: "/about/besplatnaya-stazhirovka" }
                        },
                        [_vm._v("Бесплатная стажировка")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass:
                            "link link--black header-main__item-nav-item",
                          attrs: { href: "/about/programmy-obucheniya-b2g" }
                        },
                        [_vm._v("Программы обучения B2G")]
                      )
                    ])
                  ])
                : _vm._e()
            ],
            2
          )
        }),
        0
      )
    : _vm.tag === "ul" && _vm.isCourseListPage
    ? _c(
        "ul",
        { staticClass: "v-list" },
        _vm._l(_vm.items, function(item, index) {
          return _c(
            "li",
            { key: index, staticClass: "v-list__item", class: item.class },
            [
              item.class === "v-list__w-100"
                ? _c(
                    "div",
                    {
                      staticClass: "btn",
                      on: { click: _vm.onBitrixPopupShow }
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                : item.class === "v-list__banner" &&
                  _vm.courseBannerLists.length > 0
                ? _c(
                    "swiper",
                    {
                      staticClass: "wrap",
                      attrs: { options: _vm.bannerOptionCourse }
                    },
                    _vm._l(_vm.courseBannerLists, function(item) {
                      return _c(
                        "swiper-slide",
                        {
                          attrs: {
                            "data-action": item.action,
                            "data-link": item.link
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "banner",
                            attrs: { src: item.image, alt: "" }
                          })
                        ]
                      )
                    }),
                    1
                  )
                : item.class === "v-list__hidden"
                ? _c("div")
                : _vm._t("item", null, { item: item, index: index })
            ],
            2
          )
        }),
        0
      )
    : _vm.tag === "ul"
    ? _c(
        "ul",
        { staticClass: "v-list" },
        _vm._l(_vm.items, function(item, index) {
          return _c(
            "li",
            { key: item[_vm.keyField] || index, staticClass: "v-list__item" },
            [_vm._t("item", null, { item: item, index: index })],
            2
          )
        }),
        0
      )
    : _vm.tag === "ol"
    ? _c(
        "ol",
        { staticClass: "v-list" },
        _vm._l(_vm.items, function(item, index) {
          return _c(
            "li",
            { key: item[_vm.keyField] || index, staticClass: "v-list__item" },
            [_vm._t("item", null, { item: item, index: index })],
            2
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }