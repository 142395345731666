const initialState = {
    staticBlock: {},
    breadcrumbs: [],
};

export default function(newState = {}) {
    return {
        namespaced: true,
        state: Object.assign(initialState, newState),
        mutations: {},
        actions: {},
    };
}
