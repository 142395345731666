<template>
    <button class="burger" :class="{ 'is-open': isOpen }" @click="onClick">
        <div class="burger__line" />
    </button>
</template>

<script>
import './burger.css';

export default {
    name: 'burger',
    props: {
        isOpen: {
            type: Boolean,
        },
    },
    methods: {
        onClick() {
            this.$emit('update:isOpen', !this.isOpen);
            this.$emit('isOpenChanged', !this.isOpen);
        },
    },
};
</script>
