var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "swiper",
    { attrs: { options: _vm.bannerOption } },
    _vm._l(_vm.banners, function(item, index) {
      return _c(
        "swiper-slide",
        { key: index, attrs: { "data-action": item.action } },
        [
          _c("a", { attrs: { href: _vm.getHref(item) } }, [
            _vm.homePage && item.image_home
              ? _c("img", {
                  staticClass: "header-main__banner-image",
                  attrs: { src: item.image_home, alt: "course banner" }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isMobile && !_vm.homePage
              ? _c("img", {
                  staticClass: "header-main__banner-image",
                  attrs: { src: item.image_mobile, alt: "course banner" }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.isMobile && !_vm.homePage
              ? _c("img", {
                  staticClass: "header-main__banner-image",
                  attrs: { src: item.image, alt: "course banner" }
                })
              : _vm._e()
          ])
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }