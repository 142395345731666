import Vuex from 'vuex';
import Vue from 'vue';

import authModule from './modules/auth';
import userModule from './modules/user';
import cartModule from './modules/cart';
import wishlistModule from './modules/wishlist';
import layoutModule from './modules/layout';
import modalModule from './modules/modal';

Vue.use(Vuex);

// Boot the current Vue component
const { dataset } = document.getElementById('app') || {};
const title = dataset.title || 'Title';
const env = dataset.env ? JSON.parse(dataset.env) : {};
const { auth = {}, user = {}, cart = {}, wishlist = {}, ...layout } = dataset.layout ? JSON.parse(dataset.layout) : {};

/**
 * Инициализация стора страницы
 */
export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    state: {
        title,
        env,
    },
    modules: {
        auth: authModule(auth),
        user: userModule(user),
        cart: cartModule(cart),
        wishlist: wishlistModule(wishlist),
        layout: layoutModule(layout || {}),
        modal: modalModule(),
    },
});
