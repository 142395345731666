import { mapState, mapActions, mapGetters } from 'vuex';

/**
 * Миксин для избранного
 */
export default (includeMethods = true, includeComputed = true) => {
    const mixin = {};
    if (includeMethods === true)
        mixin.methods = {
            ...mapActions('wishlist', {
                wishlist_ClearWishlist: 'clearWishlist',
                wishlist_ToggleWishlist: 'toggleWishlist',
                wishlist_CartItemToggleWishlist: 'cartItemToggleWishlist',
            }),
        };
    if (includeComputed === true)
        mixin.computed = {
            ...mapGetters('wishlist', {
                wishlist_InWishlist: 'inWishlist',
                wishlist_CartItemInWishlist: 'cartItemInWishlist',
            }),
            ...mapState('wishlist', {
                wishlist_Items: 'items',
            }),
        };
    return mixin;
};
