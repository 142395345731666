import { mapState, mapActions, mapGetters } from 'vuex';

export default (includeMethods = true, includeComputed = true) => {
    const mixin = {};
    if (includeComputed)
        mixin.computed = {
            ...mapState('user', {
                user_Id: 'id',
                user_Email: 'email',
                user_Image: 'image',
                user_LastName: 'last_name',
                user_MiddleName: 'middle_name',
                user_Name: 'name',
                user_Phone: 'phone',
                user_Addresses: 'addresses',
                user_Addresses_loaded: 'addresses_loaded',
            }),
            ...mapGetters('user', {
                user_FullName: 'fullName',
            }),
        };

    if (includeMethods)
        mixin.methods = {
            ...mapActions('user', {
                user_Subscribe: 'subscribe',
                user_UpdateUserInfo: 'updateUserInfo',
                user_UpdateUserAddress: 'updateUserAddress',
                user_SaveUserInfo: 'saveUserInfo',
            }),
        };
    return mixin;
};
