<template>
    <transition-group v-if="name" class="v-list" :tag="tag" :name="name" :appear="appear">
        <li class="v-list__item" v-for="(item, index) in items" :key="item[keyField] || index">
            <slot name="item" :item="item" :index="index" />
        </li>
    </transition-group>
    <ul v-else-if="tag === 'ul' && isHeaderMenu" class="v-list">
        <li class="v-list__item" v-for="(item, index) in items" :key="item[keyField] || index"
            :class="item.name === 'О нас' ? 'header-main__topmenu' : ''" >
            <slot name="item" :item="item" :index="index" />
            <ul v-if="item.name === 'О нас'" class="header-main__submenu">
                <li><a href="/reviews" class="link link--black header-main__item-nav-item">Отзывы</a></li>
                <li><a href="/students" class="link link--black header-main__item-nav-item">Студенты</a></li>
                <li><a href="/about/besplatnaya-stazhirovka" class="link link--black header-main__item-nav-item">Бесплатная стажировка</a></li>
                <li><a href="/about/programmy-obucheniya-b2g" class="link link--black header-main__item-nav-item">Программы обучения B2G</a></li>
            </ul>
        </li>
    </ul>
    <ul v-else-if="tag === 'ul' && isCourseListPage" class="v-list">
        <li class="v-list__item" v-for="(item, index) in items" :key="index"
            :class="item.class"
            >
            <!-- синяя кнопка на три ряда -->
            <div v-if="item.class === 'v-list__w-100'" class="btn" @click="onBitrixPopupShow">{{ item.name }}</div>
            <!-- слайдер с баннерами -->
            <swiper v-else-if="item.class === 'v-list__banner' && courseBannerLists.length > 0" 
                :options="bannerOptionCourse" class="wrap" >
                <swiper-slide v-for="(item) in courseBannerLists" :data-action="item.action" :data-link="item.link" >
                    <img class="banner" :src="item.image" alt=""/>
                </swiper-slide>
            </swiper>
            <!-- скрытый блок для выравнивания вёрстки -->
            <div v-else-if="item.class === 'v-list__hidden'"></div>
            <!-- курс -->
            <slot v-else name="item" :item="item" :index="index" />
        </li>
    </ul>
    <ul v-else-if="tag === 'ul'" class="v-list">
        <li class="v-list__item" v-for="(item, index) in items" :key="item[keyField] || index">
            <slot name="item" :item="item" :index="index" />
        </li>
    </ul>
    <ol v-else-if="tag === 'ol'" class="v-list">
        <li class="v-list__item" v-for="(item, index) in items" :key="item[keyField] || index">
            <slot name="item" :item="item" :index="index" />
        </li>
    </ol>
</template>

<script>
import 'swiper/dist/css/swiper.css';
import {swiper, swiperSlide} from 'vue-awesome-swiper';
import './VList.css';
const validTags = ['ul', 'ol'];

export default {
    name: 'v-list',
    components: {
        swiper,
        swiperSlide
    },
    data() {
        return {
            bannerOptionCourse: {
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                },
                allowTouchMove: false,
                effect: 'fade',
                loop: true,
                on: {
                    click: function () {
                        let curSlide = this.slides[ this.activeIndex ];
                        let action = curSlide.getAttribute('data-action');
                        if (action == 'open_link')
                            window.location.href = curSlide.getAttribute('data-link');
                        else if (action == 'open_b24_1')
                            document.getElementById('show-b24-feedback-popup').click();
                        else if (action == 'open_b24_2')
                            document.getElementById('show-b24-feedback-popup-2').click();
                    },
                },
            }
        }
    },
    props: {
        courseBannerLists: {
            type: Array,
            default: () => [],
        },
        tag: {
            type: String,
            default: 'ul',
            validator(value) {
                return validTags.indexOf(value) !== -1;
            },
        },
        keyField: {
            type: String,
        },
        items: {
            type: Array,
            default: () => [],
        },
        name: {
            type: String,
        },
        appear: {
            type: Boolean,
            default: false,
        },
        isHeaderMenu: {
            type: Boolean,
            default: false,            
        },
        isCourseListPage: {
            type: Boolean,
            default: false,            
        }
    },
    methods: {
        onBitrixPopupShow() {
            document.getElementById('show-b24-feedback-popup').click();
        },
        /*onBannerPopupShow() {
            window.location.href = '/courses/onlayn-klass-e-commerce-360?popup=show';
        },*/
    },
};
</script>
