<template>
    <div @click="onClose" class="modal-mask">
        <div
            v-scroll-lock="lock"
            ref="wrapper"
            class="modal-wrapper"
            tabindex="0"
            @keydown="keyDown"
            :class="{ 'modal-wrapper--fullscreen': type === 'fullscreen' }"
        >
            <div
                class="modal-container popup"
                :class="{ [`popup--${type}`]: type, [`popup--${name}`]: name }"
                ref="popup"
                @mousedown="onPopupMouseDown"
                @mouseup="onPopupMouseUp"
            >
                <div class="modal-header popup__header">
                    <slot name="header">
                        <!-- Default header -->
                    </slot>
                </div>

                <div class="modal-body popup__body">
                    <slot name="body">
                        <!-- Default body -->
                    </slot>
                </div>

                <!--div class="modal-footer popup__footer">
                            <slot name="footer">
                            </slot>
        </div-->
                <button type="button" title="Закрыть" class="modal-default-button popup__close" @click="$emit('close')">
                    <svg class="icon icon--cross-big">
                        <use xlink:href="#icon-cross"></use>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import './modal.css';

export default {
    name: 'modal',
    props: {
        type: {
            type: String,
        },
        name: {
            type: String,
        },
        closeOnBtn: {
            type: Boolean,
            default: true,
        },
        isScrollLocked: {
            type: Boolean,
            default: true,
        },
        close: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            clickInside: false,
            lock: false,
        };
    },
    methods: {
        onPopupMouseDown(e) {
            this.clickInside = true;
        },
        onPopupMouseUp(e) {
            this.clickInside = false;
        },
        onClose(e) {
            const { popup } = this.$refs;
            if (popup && popup !== e.target && !popup.contains(e.target)) {
                if (!this.clickInside) this.$emit('close');
                else this.clickInside = false;
            }
        },
        keyDown(e) {
            switch (e.key) {
                case 'Escape':
                    if (this.closeOnBtn) this.$emit('close');
                    e.preventDefault();
                    break;
            }
        },
    },
    mounted() {
        this.lock = this.isScrollLocked;
        this.$refs.wrapper.focus();
    },
    beforeDestroy() {
        this.lock = false;
    },
};
</script>
