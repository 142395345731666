var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { ref: "header", staticClass: "header-main" },
    [
      _c("div", { staticClass: "header-main__banner-top" }, [
        _c(
          "div",
          { staticClass: "header-main__banner-image-wrapper course-banner" },
          [
            _c("CourseBanner", { attrs: { banners: _vm.layout_CourseBanners } })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "header-main__sticky-container",
          class: { "header-main__sticky-container--sticky": _vm.isSticky }
        },
        [
          _c("div", { staticClass: "header-main__top" }, [
            _c("div", { staticClass: "container header-main__container" }, [
              _c("div", { staticClass: "header-main__banner" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "header-main__banner-image-wrapper course-banner"
                  },
                  [
                    _c("CourseBanner", {
                      attrs: { banners: _vm.layout_CourseBanners }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "header-main__right-block" },
                [
                  _c(
                    "ul",
                    { staticClass: "header-main__socials" },
                    _vm._l(_vm.socialIcons, function(icon) {
                      return _c("li", { key: icon.name }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: icon.url,
                              target: "_blank",
                              "aria-label": icon.name
                            },
                            on: {
                              click: function($event) {
                                return _vm.sendMetric(
                                  "click_social",
                                  "click",
                                  "social"
                                )
                              }
                            }
                          },
                          [_c("v-svg", { attrs: { name: icon.icon } })],
                          1
                        )
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "v-link",
                    {
                      staticClass:
                        "link--black header-main__item header-main__item-phone comagic_phone",
                      attrs: {
                        href: _vm.mainPhoneHref,
                        "icon-name": _vm.isTablet ? "call" : null,
                        "aria-label": _vm.layout_StaticBlock.main_phone
                      },
                      on: {
                        click: function($event) {
                          _vm.showPhoneCallback()
                          _vm.sendMetric("click_phone", "click", "phone")
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "header-main__item-phone-text" },
                        [_vm._v(_vm._s(_vm.layout_StaticBlock.main_phone))]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "header-main__search" },
                    [
                      _c("v-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.show_search,
                            expression: "show_search"
                          }
                        ],
                        staticClass: "header-main__search-input",
                        attrs: {
                          placeholder: "Поиск...",
                          "aria-label": "Поиск"
                        },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.onStartSearch($event)
                          }
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "before",
                            fn: function() {
                              return [
                                _c("v-button", {
                                  staticClass: "header-main__search-btn-search",
                                  attrs: { iconName: "search" },
                                  on: { click: _vm.onStartSearch }
                                })
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "after",
                            fn: function() {
                              return [
                                _c("v-button", {
                                  staticClass: "header-main__search-btn-close",
                                  attrs: { iconName: "cross" },
                                  on: { click: _vm.onCloseSearch }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.search_text,
                          callback: function($$v) {
                            _vm.search_text = $$v
                          },
                          expression: "search_text"
                        }
                      }),
                      _vm._v(" "),
                      _c("v-button", {
                        staticClass: "header-main__search-btn-show",
                        attrs: { iconName: "search" },
                        on: { click: _vm.onShowSearch }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "header-main__item header-main__item-menu" },
                    [
                      _c(
                        "v-link",
                        {
                          staticClass: "link--black header-main__item",
                          attrs: {
                            tag: "button",
                            "icon-name": _vm.cabinetIconName,
                            "aria-label": _vm.auth_IsGuest
                              ? "Войти"
                              : _vm.user_FullName
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.onCabinetBtnClick($event)
                            },
                            touchstart: function($event) {
                              $event.stopPropagation()
                            }
                          }
                        },
                        [
                          _c(
                            "v-clamp",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isTablet,
                                  expression: "!isTablet"
                                }
                              ],
                              class: {
                                "header-main__item-menu-login": _vm.auth_IsGuest
                              },
                              attrs: { maxLines: 1, autoresize: "" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.auth_IsGuest ? "Войти" : _vm.user_FullName
                                )
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "transition",
                        { attrs: { name: "fade" } },
                        [
                          _vm.isCabinetOpen &&
                          !_vm.auth_IsGuest &&
                          !_vm.isTabletLg
                            ? _c("menu-header", {
                                ref: "menu",
                                staticClass: "header-main__popup-menu",
                                attrs: {
                                  "key-field": "name",
                                  type: "cabinet",
                                  items: _vm.cabinetLinks
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-link", {
                    staticClass: "link--black header-main__item bvi-open",
                    attrs: {
                      tag: "button",
                      "icon-name": "eye-2",
                      placement: "before",
                      title: "Версия для слабовидящих",
                      "aria-label": "Версия для слабовидящих"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-link",
                    {
                      staticClass:
                        "link--black header-main__item header-main__item-cart",
                      attrs: {
                        href: "/cart",
                        title: "Корзина",
                        "aria-label": "Корзина"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "header-main__item-cart-inner" },
                        [
                          _c("v-svg", { attrs: { name: "cart" } }),
                          _vm._v(" "),
                          _vm.cart_Items.length > 0
                            ? _c(
                                "span",
                                { staticClass: "header-main__item-cart-count" },
                                [_vm._v(_vm._s(_vm.cart_Items.length))]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.cart_Items.length > 0
                        ? _c(
                            "div",
                            { staticClass: "header-main__item-cart-price" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.preparePrice(_vm.cart_Price)) +
                                  "\n                            "
                              ),
                              _c("span", [_vm._v("₽")])
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "header-main__panel" }, [
            _c(
              "div",
              { staticClass: "container header-main__container" },
              [
                _c("burger", {
                  staticClass: "header-main__item header-main__item-burger",
                  attrs: {
                    isOpen: _vm.isMenuOpen,
                    "aria-label": "Открыть меню навигации"
                  },
                  on: {
                    "update:isOpen": function($event) {
                      _vm.isMenuOpen = $event
                    },
                    "update:is-open": function($event) {
                      _vm.isMenuOpen = $event
                    }
                  }
                }),
                _vm._v(" "),
                _c("v-link", {
                  staticClass: "header-main__item header-main__item-logo",
                  attrs: { href: "/", "icon-name": "logo" }
                }),
                _vm._v(" "),
                _c(
                  "nav",
                  { staticClass: "header-main__item-nav" },
                  [
                    _c("v-list", {
                      staticClass: "header-main__item-nav-list",
                      attrs: {
                        "key-field": "name",
                        items: _vm._f("noFalse")(_vm.layout_MenuTop),
                        isHeaderMenu: true
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "item",
                          fn: function(ref) {
                            var link = ref.item
                            return [
                              _c(
                                "v-link",
                                {
                                  class: _vm.checkUrl(link.href)
                                    ? "link--black header-main__item-nav-item active"
                                    : "link--black header-main__item-nav-item",
                                  attrs: { href: link.href }
                                },
                                [
                                  _vm._v(
                                    _vm._s(link.name) +
                                      "\n                            "
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "slide-top" } },
            [
              (_vm.isMenuOpen || (_vm.isCabinetOpen && !_vm.auth_IsGuest)) &&
              _vm.isTabletLg
                ? _c("menu-header", {
                    directives: [
                      {
                        name: "scroll-lock",
                        rawName: "v-scroll-lock",
                        value:
                          (_vm.isMenuOpen ||
                            (_vm.isCabinetOpen && !_vm.auth_IsGuest)) &&
                          _vm.isTabletLg,
                        expression:
                          "(isMenuOpen || (isCabinetOpen && !auth_IsGuest)) && isTabletLg"
                      }
                    ],
                    staticClass: "header-main__popup-menu",
                    attrs: {
                      "key-field": "name",
                      type: _vm.isCabinetOpen ? "cabinet" : "menu",
                      items: _vm.isCabinetOpen
                        ? _vm.cabinetLinks
                        : _vm.layout_MenuTop
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "top",
                          fn: function(ref) {
                            var itemClass = ref.itemClass
                            return [
                              _vm.isCabinetOpen
                                ? _c(
                                    "v-clamp",
                                    {
                                      staticClass:
                                        "menu-header__nav-item header-main__popup-menu-name",
                                      attrs: {
                                        tag: "div",
                                        autoresize: "",
                                        maxLines: 1
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.user_FullName))]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2315074127
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("modal-login", {
        on: {
          closed: function($event) {
            _vm.isCabinetOpen = false
          }
        }
      }),
      _vm._v(" "),
      _c("modal-sms-login", {
        on: {
          closed: function($event) {
            _vm.isCabinetOpen = false
          }
        }
      }),
      _vm._v(" "),
      _c("modal-forgot-password", {
        on: {
          closed: function($event) {
            _vm.isCabinetOpen = false
          }
        }
      }),
      _vm._v(" "),
      _c("modal-phone-callback", {
        on: {
          showPostLoader: function($event) {
            _vm.show_post_loader = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show_post_loader,
              expression: "show_post_loader"
            }
          ],
          staticClass: "post-loader"
        },
        [_c("loader")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }