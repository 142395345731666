<template>
    <transition name="modal">
        <modal
            class="modal-login"
            v-if="modal_IsOpen"
            type="auth"
            @close="modal_ChangeState({ name: 'sms_login', open: false })"
        >
            <form-sms-login slot="body" class="modal-login__form" :autofocus="!isTabletLg" />
        </modal>
    </transition>
</template>

<script>
import Modal from '../controls/modal/modal.vue';
import FormSmsLogin from '../form-sms-login/form-sms-login.vue';

import ModalMixin from '../../mixins/modal';
import './modal-sms-login.css';

export default {
    name: 'modal-sms-login',
    mixins: [ModalMixin('sms_login')],
    components: {
        Modal,
        FormSmsLogin,
    },
    computed: {
        isTabletLg() {
            return this.$mq.tabletLg;
        },
    },
    watch: {
        modal_IsOpen(value) {
            if (!value) this.$emit('closed');
        },
    },
};
</script>
