import Wishlist from '../../../scripts/wishlist';
import { idProductOfItem, TYPE_COURSE, TYPE_COURSE_SPAN, TYPE_COURSE_TARIFF, typeOfItem } from '../../../scripts/cart-item';

const initialState = {
    /**
     * Продукты в избранном
     */
    items: [],
};

export default function(newState = {}) {
    return {
        namespaced: true,
        state: Object.assign(initialState, newState),
        getters: {
            /**
             * Проверка наличия продукта в избранном
             */
            inWishlist: state => (type, id) => {
                const { items } = state;
                return items.some(i => i.type === type && i.id === id);
            },

            /**
             * Проверка наличия продукта, который уже находится в корзине, в избранном
             * @param {*} item
             */
            cartItemInWishlist: (state, getters) => item => {
                const type = typeOfItem(item);
                const id = type === TYPE_COURSE ? item.course_id : idProductOfItem(item);
                return getters.inWishlist(type, id);
            },
        },
        mutations: {
            /**
             * Изменение коллекции продуктов в избранном
             * @param {*} state
             * @param {Array} items
             */
            changeItems(state, items) {
                state.items = items;
            },
        },
        actions: {
            /**
             * Очистить избранное пользователя по типу (курсы, документы)
             * @param {String} type
             */
            clearWishlist({ dispatch, commit, rootState }, type) {
                // Если пользователь не зашел, заставляем его залогиниться
                if (!rootState.auth.isAuthorized) {
                    dispatch('modal/changeState', { name: 'login', open: true }, { root: true });
                    return;
                }
                Wishlist.clear(type).then(resp => commit('changeItems', resp.items));
            },

            /**
             * Изменение состояния продукта в избранном пользователя, по типу и идентификатору
             * @param {String} type
             * @param {*} id
             */
            toggleWishlist({ dispatch, commit, rootState }, { type, id }) {
                // Если пользователь не зашел, заставляем его залогиниться
                if (!rootState.auth.isAuthorized) {
                    dispatch('modal/changeState', { name: 'login', open: true }, { root: true });
                    return;
                }
                Wishlist.toggle(type, id).then(resp => commit('changeItems', resp.items));
            },

            /**
             * Изменение состояния продукта, который уже находится в корзине, в избранном пользователя
             * @param {*} item
             */
            cartItemToggleWishlist({ dispatch, rootState }, item) {
                if (!rootState.auth.isAuthorized) {
                    dispatch('modal/changeState', { name: 'login', open: true }, { root: true });
                    return;
                }

                const rawType = typeOfItem(item);
                const type = rawType === TYPE_COURSE ? TYPE_COURSE_SPAN : rawType;
                dispatch('wishlist/toggleWishlist', { type, id: idProductOfItem(item) }, { root: true });
            },
        },
    };
}
