<template>
    <transition name="modal">
        <modal
            class="modal-phone-callback"
            @close="modal_ChangeState({ name: 'phone_callback', open: false })"
            v-if="modal_IsOpen"
        >
            <form ref="lennufForm" slot="body" class="form modal-phone-callback__form" @submit.prevent="sendRequest" novalidate>
                <h3 class="form__hl modal-phone-callback__hl">
                    Мы не в офисе
                </h3>
                
                <div class="form__cols">
                    Мы работаем понедельник-пятница с 10 до 19<br>
                    Можем перезвонить Вам в назначенное время
                </div>

                <div class="form__cols">
                    <v-input
                        class="form__col"
                        v-model="phone"
                        v-mask="'+7(###) ###-##-##'"
                        type="tel"
                        placeholder="+7(___) ___-___-__"
                        :error="phone_error"
                        aria-required="true"
                    >
                        Телефон <span aria-hidden="true">*</span>
                        <template v-slot:error="{ error }">
                            <transition name="slide-in-bottom" mode="out-in">
                                <div :key="error" v-if="error">{{ error }}</div>
                            </transition>
                        </template>
                    </v-input>

                    <v-input
                        class="form__col"
                        v-model="name"
                        placeholder="Имя"
                        :error="name_error"
                    >
                        Имя
                        <template v-slot:error="{ error }">
                            <transition name="slide-in-bottom" mode="out-in">
                                <div :key="error" v-if="error">{{ error }}</div>
                            </transition>
                        </template>
                    </v-input>
                </div>
                
                <div class="form__cols">
                    <div class="form__cols">
                        <v-input
                            class="form__col form__col-number"
                            v-model="hours"
                            v-mask="'##'"
                            placeholder=""
                            @change="inputHours"
                        ></v-input>
                        <div class="form__col-colon">:</div>
                        <v-input
                            class="form__col form__col-number"
                            v-model="minutes"
                            v-mask="'##'"
                            placeholder=""
                            @change="inputMinutes"
                        ></v-input>
                    </div>
                    <v-select
                        class="detail-info__row-select"
                        v-model="timezone"
                        :items="timeZones"
                    />
                </div>
                
                <div class="form__cols">
                    <div class="modal-phone-callback__policy" v-if="auth_IsGuest">
                        <input
                            type="checkbox"
                            v-model="policy"
                            id="modal-phone-callback-policy"
                            aria-required="true"
                            aria-describedby="modal-discount-policy-error"
                        />
                        <label for="modal-phone-callback-policy" class="modal-phone-callback__policy-label">
                            Согласен с
                            <v-link  href="/policy" target="_blank">
                                условиями обработки персональных данных
                            </v-link>
                        </label>
                    </div>
                </div>
                
                <div class="modal-phone-callback__error" v-if="auth_IsGuest">
                    <transition name="slide-in-bottom" mode="out-in">
                        <div
                            id="modal-discount-policy-error"
                            class="error-message modal-phone-callback__error-message"
                            role="alert"
                            v-show="policy_error"
                        >
                            {{ policy_error }}
                        </div>
                    </transition>
                </div>
                
                <div class="form__cols modal-phone-callback__submit">
                    <v-button class="modal-phone-callback__submit-btn" type="submit">Отправить</v-button>
                </div>
            </form>
        </modal>
    </transition>
</template>

<script>
import VButton from '../controls/VButton/VButton.vue';
import VInput from '../controls/VInput/VInput.vue';
import VLink from '../controls/VLink/VLink.vue';
import VSelect from '../../components/controls/VSelect/VSelect.vue';
import Modal from '../controls/modal/modal.vue';

import UserMixin from '../../mixins/user';
import ModalMixin from '../../mixins/modal';
import AuthMixin from '../../mixins/auth';
import LayoutMixin from '../../mixins/layout';
import MetricMixin from '../../mixins/metric';
import Services from '../../../scripts/services/services';
import { validationMixin, required, email, tel, minValue, maxValue } from '../../../scripts/validation';
import './modal-phone-callback.css';

export default {
    name: 'modal-phone-callback',
    mixins: [validationMixin, LayoutMixin, ModalMixin('phone_callback'), AuthMixin(false), UserMixin(false), MetricMixin],
    components: {
        VButton,
        VLink,
        VInput,
        Modal,
        VSelect,
        //Loader,
    },
    props: ['inpAjaxLink'],
    validations() {
        return {
            phone: {
                tel,
                required,
            },
            name: {},
            policy: {
                required: value => !this.auth_IsGuest || value === true,
            },
        };
    },
    data() {
        return {
            phone: '',
            name: '',
            hours: '11',
            minutes: '00',
            timezone: 'Москва (GMT+3)',
            policy: false,
            ajaxLink: (this.inpAjaxLink) ? this.inpAjaxLink : '/ajax/create-phone-callback-request',
            timeZones: [
                'Москва (GMT+3)',
                'Калининград (GMT+2)',
                'Самара (GMT+4)',
                'Новосибирск (GMT+7)',
                'Иркутск (GMT+8)',
                'Якутск (GMT+9)',
            ],
        };
    },
    computed: {
        isTabletLg() {
            return this.$mq.tabletLg;
        },
        name_error() {
        },
        phone_error() {
            if (this.$v.phone.$dirty) {
                if (!this.$v.phone.required) return 'Обязательное поле';
                if (!this.$v.phone.tel) return 'Формат +7(000) 00-00-00';
            }
        },
        policy_error() {
            return this.$v.policy.$dirty && !this.$v.policy.required
                ? 'Необходимо подтвердить cогласие с условиями обработки персональных данных'
                : null;
        },
    },
    watch: {
        modal_IsOpen(value) {
            if (!value) this.$emit('closed');
            else this.resetForm();
        },
    },
    methods: {
        inputMinutes() {
            let m = parseInt(this.minutes);
            if (m > 59 || m < 0) this.minutes = 0;
        },
        inputHours() {
            let h = parseInt(this.hours);
            if (h > 23 || h < 0) this.hours = 0;
        },
        setDocument(val) {
            this.document = val;
        },
        resetForm() {
            if (!this.auth_IsGuest) {
                this.name = `${this.user_LastName} ${this.user_Name}`;
                this.phone = this.user_Phone;
            } else {
                this.name = '';
                this.phone = '';
            }
            this.policy = false;
            this.$v.$reset();
        },

        sendRequest(e) {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                this.$emit('showPostLoader', true);
                let ymClientID; ym(44606269, 'getClientID', function(clientID) { ymClientID = clientID;} );
                let formData = new FormData();
                formData.append('phone', this.phone);
                formData.append('name', this.name);
                formData.append('hours', this.hours);
                formData.append('minutes', this.minutes);
                formData.append('timezone', this.timezone);
                formData.append('ymClientID', ymClientID);
                let ajaxLink = this.ajaxLink;

                Services.net()
                    .post(ajaxLink, formData)
                    .then(response => {
                        this.$emit('showPostLoader', false);
                        this.modal_ChangeState({
                            name: 'message',
                            open: true,
                            state: {message: 'Спасибо. Мы перезвоним Вам в назначенное время.'},
                        });
                        let obj = {
                            name: this.name,
                            phone: this.phone,
                            email: '',
                            message: 'Запрос на обратный звонок в ' + this.hours + ':' + this.minutes + ' ' + this.timezone,
                        }
                        console.log(obj);
                        Comagic.addOfflineRequest(obj);
                    });
            }
        },
    }
};
</script>
