var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.href
    ? _c(
        "a",
        _vm._g(
          _vm._b(
            { staticClass: "btn", attrs: { href: _vm.href } },
            "a",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _vm.iconName && _vm.placement === "before"
            ? _c("v-svg", {
                attrs: { name: _vm.iconName, modifier: _vm.iconModifier }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default"),
          _vm._v(" "),
          _vm.iconName && _vm.placement === "after"
            ? _c("v-svg", {
                attrs: { name: _vm.iconName, modifier: _vm.iconModifier }
              })
            : _vm._e()
        ],
        2
      )
    : _c(
        "button",
        _vm._g(
          _vm._b(
            { staticClass: "btn", attrs: { type: _vm.type } },
            "button",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _vm.iconName && _vm.placement === "before"
            ? _c("v-svg", {
                attrs: { name: _vm.iconName, modifier: _vm.iconModifier }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default"),
          _vm._v(" "),
          _vm.iconName && _vm.placement === "after"
            ? _c("v-svg", {
                attrs: { name: _vm.iconName, modifier: _vm.iconModifier }
              })
            : _vm._e()
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }