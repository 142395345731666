<template>
    <a class="btn" v-if="href" :href="href" v-bind="$attrs" v-on="$listeners">
        <v-svg v-if="iconName && placement === 'before'" :name="iconName" :modifier="iconModifier" />
        <slot />
        <v-svg v-if="iconName && placement === 'after'" :name="iconName" :modifier="iconModifier" />
    </a>
    <button class="btn" v-else :type="type" v-bind="$attrs" v-on="$listeners">
        <v-svg v-if="iconName && placement === 'before'" :name="iconName" :modifier="iconModifier" />
        <slot />
        <v-svg v-if="iconName && placement === 'after'" :name="iconName" :modifier="iconModifier" />
    </button>
</template>

<script>
import VSvg from '../VSvg/VSvg.vue';

const placements = ['before', 'after'];
const types = ['button', 'reset', 'submit'];

/**
 * Базовый класс для кнопки, поддерживает SVG
 */
export default {
    name: 'v-button',
    inheritAttrs: false,
    components: {
        VSvg,
    },
    props: {
        iconName: String,
        iconModifier: String,
        type: {
            type: String,
            default: 'button',
            validator(value) {
                return types.indexOf(value) !== -1;
            },
        },
        placement: {
            type: String,
            default: 'before',
            validator(value) {
                return placements.indexOf(value) !== -1;
            },
        },
        href: {
            type: String,
        },
    },
};
</script>
