import Services from '../../../scripts/services/services';

const initialState = {
    /**
     * Возвращает значение, показывающее, является ли юзер гостем, или уже повошел в систему
     * @returns {Boolean}
     */
    isAuthorized: false,

    /**
     * Имя юзера, вошедшего в систему
     * @returns {String}
     */
    name: '',

    /**
     * Email юзера, вошедшего в систему
     * @returns {String}
     */
    email: '',

    /**
     * ID юзера, вошедшего в систему
     * @returns {any}
     */
    id: 0,

    /**
     * Показывает необходимость залогиниться другим образом,
     * если произошел вход через социальную сеть, которая не привязана в аккакунте
     * @returns {Boolean}
     */
    needAnotherLogin: false,

    socialDrivers: [],

    suggestedDivers: [],
};

export default function(newState = {}) {
    return {
        namespaced: true,
        state: Object.assign(initialState, newState),
        mutations: {},
        actions: {
            /**
             * Logout метод
             * @returns {Promise} Promise
             */
            logout() {
                return Services.net()
                    .get('/ajax/profile/logout')
                    .then(() => {
                        window.location.href = '/';
                    });
            },

            /**
             * Login метод
             * @param {String} email
             * @param {String} password
             * @param {Boolen} remember
             * @returns {Promise} Promise
             */
            login(context, { email, password, remember }) {
                return Services.net().post('/ajax/profile/login', {
                    email,
                    password,
                    remember,
                });
            },

            /**
             * Login метод
             * @param {String} phone
             * @returns {Promise} Promise
             */
            smsSend(context, { phone }) {
                return Services.net().post('/ajax/profile/sms/send', {
                    phone,
                });
            },

            /**
             * Login метод
             * @param {String} phone
             * @param {String} code
             * @returns {Promise} Promise
             */
            smsLogin(context, { phone, code}) {
                return Services.net().post('/ajax/profile/sms/login', {
                    code,
                    phone,
                });
            },

            /**
             * Отправка Email на почту при ситуации, когда юзер забыл пароль
             * @param {String} email
             * @param {String} type
             * @returns {Promise} Promise
             */
            sendForgotMessage(context, { email, type = 'forgot' }) {
                return Services.net().post('/ajax/profile/forgot-password-send', {
                    email,
                    type,
                });
            },
        },
    };
}
