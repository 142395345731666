/* eslint-disable require-jsdoc */
import Services from './services/services';

export default class Wishlist {
    static toggle(type, id) {
        // todo если пользователь не авторизован, надо показывать попап входа (возможно стоит добавить разъясняющий текст)
        return Services.net().get(`/ajax/profile/favorite/${type}/${id}`);
    }

    static clear(type) {
        return Services.net().get(`/ajax/profile/favorite/clear/${type}`);
    }
}
