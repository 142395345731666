var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "v-password",
      class: { "is-disabled": _vm.$attrs.disabled }
    },
    [
      _c(
        "label",
        { staticClass: "v-password__label", attrs: { for: _vm.inputId } },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "v-password__container" },
        [
          _c(
            "input",
            _vm._g(
              _vm._b(
                {
                  ref: "input",
                  staticClass: "v-password__input",
                  class: { "is-invalid": _vm.error },
                  attrs: {
                    id: _vm.inputId,
                    type: _vm.type,
                    autocomplete: _vm.autocomplete,
                    "aria-describedby": _vm.inputId + "-alert"
                  },
                  domProps: { value: _vm.value },
                  on: {
                    keydown: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      $event.preventDefault()
                    }
                  }
                },
                "input",
                _vm.$attrs,
                false
              ),
              _vm.inputListeners
            )
          ),
          _vm._v(" "),
          !_vm.$attrs.disabled
            ? _c("v-button", {
                staticClass: "btn--transparent v-password__btn-show",
                attrs: { iconName: _vm.showPassword ? "eye-crossed" : "eye" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.changePasswordState($event)
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "error-message v-password__error",
          attrs: { id: _vm.inputId + "-alert", role: "alert" }
        },
        [
          _vm._t(
            "error",
            [_vm._v("\n            " + _vm._s(_vm.error) + "\n        ")],
            { error: _vm.error }
          )
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }