<template>
    <transition name="modal">
        <modal
            class="modal-form-subscribe"
            @close="modal_ChangeState({ name: 'subscribe', open: false })"
            v-if="modal_IsOpen"
            type="sm"
        >
            <v-svg slot="header" class="modal-form-subscribe__header" name="news-subscribe" />
            <form slot="body" class="form modal-form-subscribe__form" @submit.prevent="sendRequest" novalidate>
                <h3 class="form__hl modal-form-subscribe__hl">Хочу получать новости</h3>
                <v-input
                    class="form__col"
                    type="email"
                    v-model="form.email"
                    placeholder="name@domain.com"
                    :error="email_error"
                    aria-required="true"
                >
                    E-mail<span aria-hidden="true">*</span>
                    <template v-slot:error="{ error }">
                        <transition name="slide-in-bottom" mode="out-in">
                            <div :key="error" v-if="error">{{ error }}</div>
                        </transition>
                    </template>
                </v-input>
                <v-input
                    class="form__col"
                    v-model="form.name"
                    placeholder="Введите Имя"
                    :error="name_error"
                    aria-required="true"
                >
                    Имя и фамилия<span aria-hidden="true">*</span>
                    <template v-slot:error="{ error }">
                        <transition name="slide-in-bottom" mode="out-in">
                            <div :key="error" v-if="error">{{ error }}</div>
                        </transition>
                    </template>
                </v-input>
                <div class="form__col modal-form-subscribe__recaptcha">
                    <vue-recaptcha
                        class="modal-form-subscribe__recaptcha-widget"
                        :class="{ 'is-invalid': recaptcha_error }"
                        :sitekey="$store.state.env.recaptcha_key"
                        ref="recaptcha"
                        @verify="onRecaptchaVerify"
                        @expired="onRecaptchaExpired"
                    />
                    <div class="modal-form-subscribe__recaptcha-error">
                        <transition name="slide-in-bottom" mode="out-in">
                            <div
                                class="error-message modal-form-subscribe__recaptcha-error-message"
                                v-show="recaptcha_error"
                                role="alert"
                            >
                                {{ recaptcha_error }}
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="form__cols modal-form-subscribe__submit">
                    <v-link
                        class="modal-form-subscribe__submit-link"
                        tag="button"
                        @click="modal_ChangeState({ name: 'subscribe', open: false })"
                    >
                        Нет, спасибо
                    </v-link>
                    <v-button class="form__submit-btn" type="submit">Подписаться</v-button>
                </div>
            </form>
        </modal>
    </transition>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import VSvg from '../controls/VSvg/VSvg.vue';
import VLink from '../controls/VLink/VLink.vue';
import VButton from '../controls/VButton/VButton.vue';
import VInput from '../controls/VInput/VInput.vue';
import Modal from '../controls/modal/modal.vue';

import { mapState } from 'vuex';
import { validationMixin, email, required } from '../../../scripts/validation';
import ModalMixin from '../../mixins/modal';
import UserMixin from '../../mixins/user';
import MetricMixin from '../../mixins/metric';
import '../../../images/sprite/news-subscribe.svg';
import './modal-form-subscribe.css';

export default {
    name: 'modal-form-subscribe',
    mixins: [validationMixin, ModalMixin('subscribe'), UserMixin(true, false), MetricMixin],
    props: {
        email: {
            type: String,
            default: '',
        },
    },
    components: {
        VSvg,
        VButton,
        VLink,
        VInput,
        Modal,
        VueRecaptcha,
    },
    validations: {
        form: {
            name: {
                required,
            },
            email: {
                required,
                email,
            },
        },
        recaptchaToken: {
            required,
        },
    },
    data() {
        return {
            form: {
                email: this.email,
                name: '',
                getClientID: '',
            },
            recaptchaToken: null,
        };
    },
    computed: {
        ...mapState('auth', {
            /**
             * Возвращает значение, показывающее, является ли юзер гостем, или уже повошел в систему
             * @returns {Boolean}
             */
            auth_IsGuest: state => !state.isAuthorized,
        }),

        name_error() {
            return this.$v.form.name.$dirty && !this.$v.form.name.required ? 'Обязательное поле' : null;
        },

        email_error() {
            if (this.$v.form.email.$dirty) {
                if (!this.$v.form.email.required) return 'Обязательное поле';
                if (!this.$v.form.email.email) return 'Формат name@domain.com';
            }
        },

        recaptcha_error() {
            return this.$v.recaptchaToken.$dirty && !this.$v.recaptchaToken.required
                ? 'Подтвердите, что вы не робот'
                : null;
        },
    },
    methods: {
        onRecaptchaVerify(response) {
            this.recaptchaToken = response;
        },
        onRecaptchaExpired() {
            this.recaptchaToken = null;
            this.$refs.recaptcha.reset();
        },
        sendRequest() {
            this.$v.$touch();
            if (!this.$v.$invalid)
                this.sendMetric('form_subscribe', 'form', 'subscribe');
                this.$emit('showPostLoader', true);

                let ymClientID; ym(44606269, 'getClientID', function(clientID) { ymClientID = clientID;} );
                this.form.ymClientID = ymClientID;
                this.user_Subscribe({
                    user: this.form,
                    recaptcha: this.recaptchaToken,
                }).then(() => {
                        this.$emit('showPostLoader', false);
                        this.modal_ChangeState({
                            name: 'message',
                            open: true,
                            state: {
                                message: this.auth_IsGuest
                                    ? `Вам отправлено письмо на ${this.form.email} для подтверждения или отмены подписки.`
                                    : 'Спасибо за подписку. Отменить подписку можно в настройках Вашего профиля в личном кабинете.',
                            },
                        });
                        let obj = {
                            name: this.form.name,
                            email: this.form.email,
                            phone: '',
                            message: 'Пользователь подписался на новости'
                        };
                        console.log(obj);
                        Comagic.addOfflineRequest(obj);
                    }
                );
        },
        resetForm() {
            this.form.email = this.email;
            this.form.name = '';
            this.recaptchaToken = null;
        },
    },
    watch: {
        email(val) {
            this.form.email = val;
        },

        modal_IsOpen(value) {
            if (!value) {
                this.resetForm();
                this.$emit('closed');
            }
        },
    },
};
</script>
