<template>
    <transition name="modal">
        <modal class="modal-form-claim" @close="modal_ChangeState({ name: 'claim', open: false })" v-if="modal_IsOpen">
            <form slot="body" class="form modal-form-claim__form" @submit.prevent="sendRequest" novalidate>
                <h3 class="form__hl">{{ titleLabel }}</h3>
                <div class="form__cols">
                    <v-input
                        class="form__col"
                        v-model="name"
                        v-focus="{ active: !isTabletLg, delay: 200 }"
                        placeholder="Введите ваше имя"
                        :error="name_error"
                        aria-required="true"
                    >
                        Имя<span aria-hidden="true">*</span>
                        <template v-slot:error="{ error }">
                            <transition name="slide-in-bottom" mode="out-in">
                                <div :key="error" v-if="error">{{ error }}</div>
                            </transition>
                        </template>
                    </v-input>
                    <v-input
                        class="form__col"
                        v-model="email"
                        type="email"
                        placeholder="E-mail"
                        :error="email_error"
                        aria-required="true"
                    >
                        E-mail<span aria-hidden="true">*</span>
                        <template v-slot:error="{ error }">
                            <transition name="slide-in-bottom" mode="out-in">
                                <div :key="error" v-if="error">{{ error }}</div>
                            </transition>
                        </template>
                    </v-input>
                </div>
                <v-input
                    class="form__col modal-form-claim__message"
                    v-model="message"
                    tag="textarea"
                    rows="5"
                    :error="message_error"
                    aria-required="true"
                >
                    {{ messageLabel }}<span aria-hidden="true">*</span>
                    <template v-slot:error="{ error }">
                        <transition name="slide-in-bottom" mode="out-in">
                            <div :key="error" v-if="error">{{ error }}</div>
                        </transition>
                    </template>
                </v-input>
                <div class="form__cols form__submit modal-form-claim__submit">
                    <div class="modal-form-claim__policy" v-if="auth_IsGuest">
                        <input
                            type="checkbox"
                            v-model="policy"
                            id="modal-form-claim-policy"
                            aria-required="true"
                            aria-describedby="modal-form-claim-policy-error"
                        />
                        <label for="modal-form-claim-policy" class="modal-form-claim__policy-label">
                            Согласен с
                            <v-link  href="/policy" target="_blank">
                                условиями обработки персональных данных
                            </v-link>
                        </label>
                    </div>
                    <v-button class="modal-form-claim__submit-btn" type="submit" title="Отправить">
                        Отправить
                    </v-button>
                </div>
                <div class="modal-form-claim__error" v-if="auth_IsGuest">
                    <transition name="slide-in-bottom" mode="out-in">
                        <div
                            id="modal-form-claim-policy-error"
                            class="error-message modal-form-claim__error-message"
                            role="alert"
                            v-show="policy_error"
                        >
                            {{ policy_error }}
                        </div>
                    </transition>
                </div>
            </form>
        </modal>
    </transition>
</template>

<script>
import VButton from '../controls/VButton/VButton.vue';
import VLink from '../controls/VLink/VLink.vue';
import VInput from '../controls/VInput/VInput.vue';
import Modal from '../controls/modal/modal.vue';

import Services from '../../../scripts/services/services.js';
import ModalMixin from '../../mixins/modal';
import AuthMixin from '../../mixins/auth';
import UserMixin from '../../mixins/user';
import LayoutMixin from '../../mixins/layout';
import MetricMixin from '../../mixins/metric';
import { validationMixin, required, email } from '../../../scripts/validation';
import './modal-form-claim.css';

const claimTypes = {
    DOCUMENT: 'document',
    COURSE: 'course',
    SERVICE: 'service',
    TEACHER: 'teacher',
    CONSULTING: 'consulting',
    FAQ: 'faq',
    STUDENT: 'student',
    BANNER: 'banner',
    COLLABORATION: 'collaboration'
};

export default {
    name: 'modal-form-claim',
    mixins: [validationMixin, LayoutMixin, ModalMixin('claim'), AuthMixin(false), UserMixin(false), MetricMixin],
    components: {
        VButton,
        VLink,
        VInput,
        Modal,
    },
    validations() {
        return {
            name: {
                required,
            },
            email: {
                required,
                email,
            },
            message: {
                required,
            },
            policy: {
                required: value => !this.auth_IsGuest || value === true,
            },
        };
    },
    data() {
        return {
            name: '',
            email: '',
            message: '',
            policy: true,
        };
    },
    computed: {
        isTabletLg() {
            return this.$mq.tabletLg;
        },
        type() {
            return this.modal_State.type;
        },
        teacher_id() {
            return this.modal_State.teacher_id;
        },
        messageLabel() {
            switch (this.type) {
                case claimTypes.DOCUMENT:
                    return 'Не нашли нужное? Опишите, пожалуйста, документ который Вы ищете и какие задачи он должен решать';
                case claimTypes.SERVICE:
                    return 'Не нашли нужное? Опишите, пожалуйста, услугу которую Вы ищете и какие задачи она должена решать';
                case claimTypes.COURSE:
                    return 'Не нашли нужное? Опишите, пожалуйста, курс который Вы ищете и какие задачи он должен решать';
                case claimTypes.FAQ:
                    return 'Опишите, пожалуйста, Вашу проблему';
                case claimTypes.TEACHER:
                    return 'Ваш вопрос';
                case claimTypes.CONSULTING:
                    return 'Текст заявки';
                case claimTypes.STUDENT:
                    return 'Текст заявки';
                case claimTypes.BANNER:
                    return 'Текст заявки';
                default:
                    return 'Ваше предложение';
            }
        },
        titleLabel() {
            switch (this.type) {
                case claimTypes.DOCUMENT:
                    return 'Заявка на новый документ';
                case claimTypes.SERVICE:
                    return 'Заявка на новую услугу';
                case claimTypes.COURSE:
                    return 'Заявка на новый курс';
                case claimTypes.FAQ:
                    return 'Ваш вопрос';
                case claimTypes.TEACHER:
                    return 'Вопрос преподавателю';
                case claimTypes.CONSULTING:
                    return 'Заявка на консалтинг';
                case claimTypes.STUDENT:
                    return 'Стать студентом';
                case claimTypes.BANNER:
                    return 'Заявка';
                default:
                    return 'Ваше предложение';
            }
        },
        successText() {
            switch (this.type) {
                case claimTypes.DOCUMENT:
                case claimTypes.SERVICE:
                case claimTypes.COURSE:
                case claimTypes.CONSULTING:
                    return 'Ваша заявка принята';
                case claimTypes.TEACHER:
                    return 'Мы получили Ваш вопрос.<br>Вернемся с ответом в течение 72 часов';
                case claimTypes.FAQ:
                    return 'Вопрос отправлен';
                case claimTypes.STUDENT:
                case claimTypes.BANNER:
                    return 'Заявка отправлена';
                default:
                    return 'Ваше предложение принято';
            }
        },

        name_error() {
            return this.$v.name.$dirty && !this.$v.name.required ? 'Обязательное поле' : null;
        },

        email_error() {
            if (this.$v.email.$dirty) {
                if (!this.$v.email.required) return 'Обязательное поле';
                if (!this.$v.email.email) return 'Формат name@domain.com';
            }
        },

        message_error() {
            return this.$v.message.$dirty && !this.$v.message.required ? 'Обязательное поле' : null;
        },

        policy_error() {
            return this.$v.policy.$dirty && !this.$v.policy.required
                ? 'Необходимо подтвердить cогласие с условиями обработки персональных данных'
                : null;
        },
    },
    methods: {
        sendRequest() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                switch (this.type) {
                    case claimTypes.CONSULTING:
                        this.sendMetric('form_consult', 'form', 'consult'); break;
                    case claimTypes.TEACHER:
                        this.sendMetric('form_question', 'form', 'question'); break;
                    case claimTypes.COLLABORATION:
                        this.sendMetric('form_coop', 'form', 'coop');
                        this.$metrika.reachGoal('cotrudnichestvo_sent'); break;
                }

                let ymClientID; ym(44606269, 'getClientID', function(clientID) { ymClientID = clientID;} );
                const data = {
                    name: this.name,
                    email: this.email,
                    message: this.message,
                    type: this.type,
                    teacher_id: this.type === claimTypes.TEACHER ? this.teacher_id : undefined,
                    ymClientID: ymClientID,
                };
                this.$emit('showPostLoader', true);
                Services.net()
                    .post('/ajax/create-claim', data)
                    .then(response => {
                        this.$emit('showPostLoader', false);
                        this.modal_ChangeState({
                            name: 'message',
                            open: true,
                            state: { message: this.successText },
                        });
                        let obj = {
                            name: this.name,
                            email: this.email,
                            phone: this.phone,
                            message: this.message
                        };
                        console.log(obj);
                        Comagic.addOfflineRequest(obj);
                    });
            }
        },

        resetForm() {
            if (!this.auth_IsGuest) {
                this.name = this.user_Name;
                this.email = this.user_Email;
            } else {
                this.name = '';
                this.email = '';
            }
            this.message = '';
            this.policy = false;
            this.$v.$reset();
        },
    },
    watch: {
        modal_IsOpen(value) {
            if (!value) {
                this.resetForm();
                this.$emit('closed');
            }
        },
    },
    beforeMount() {
        if (!this.auth_IsGuest) {
            this.name = this.user_Name;
            this.email = this.user_Email;
        }
    },
};
</script>
