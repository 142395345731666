var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "modal" } },
    [
      _vm.modal_IsOpen
        ? _c(
            "modal",
            {
              staticClass: "modal-form-claim",
              on: {
                close: function($event) {
                  return _vm.modal_ChangeState({ name: "claim", open: false })
                }
              }
            },
            [
              _c(
                "form",
                {
                  staticClass: "form modal-form-claim__form",
                  attrs: { slot: "body", novalidate: "" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.sendRequest($event)
                    }
                  },
                  slot: "body"
                },
                [
                  _c("h3", { staticClass: "form__hl" }, [
                    _vm._v(_vm._s(_vm.titleLabel))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form__cols" },
                    [
                      _c(
                        "v-input",
                        {
                          directives: [
                            {
                              name: "focus",
                              rawName: "v-focus",
                              value: { active: !_vm.isTabletLg, delay: 200 },
                              expression: "{ active: !isTabletLg, delay: 200 }"
                            }
                          ],
                          staticClass: "form__col",
                          attrs: {
                            placeholder: "Введите ваше имя",
                            error: _vm.name_error,
                            "aria-required": "true"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "error",
                                fn: function(ref) {
                                  var error = ref.error
                                  return [
                                    _c(
                                      "transition",
                                      {
                                        attrs: {
                                          name: "slide-in-bottom",
                                          mode: "out-in"
                                        }
                                      },
                                      [
                                        error
                                          ? _c("div", { key: error }, [
                                              _vm._v(_vm._s(error))
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3034400235
                          ),
                          model: {
                            value: _vm.name,
                            callback: function($$v) {
                              _vm.name = $$v
                            },
                            expression: "name"
                          }
                        },
                        [
                          _vm._v("\n                    Имя"),
                          _c("span", { attrs: { "aria-hidden": "true" } }, [
                            _vm._v("*")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-input",
                        {
                          staticClass: "form__col",
                          attrs: {
                            type: "email",
                            placeholder: "E-mail",
                            error: _vm.email_error,
                            "aria-required": "true"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "error",
                                fn: function(ref) {
                                  var error = ref.error
                                  return [
                                    _c(
                                      "transition",
                                      {
                                        attrs: {
                                          name: "slide-in-bottom",
                                          mode: "out-in"
                                        }
                                      },
                                      [
                                        error
                                          ? _c("div", { key: error }, [
                                              _vm._v(_vm._s(error))
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3034400235
                          ),
                          model: {
                            value: _vm.email,
                            callback: function($$v) {
                              _vm.email = $$v
                            },
                            expression: "email"
                          }
                        },
                        [
                          _vm._v("\n                    E-mail"),
                          _c("span", { attrs: { "aria-hidden": "true" } }, [
                            _vm._v("*")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-input",
                    {
                      staticClass: "form__col modal-form-claim__message",
                      attrs: {
                        tag: "textarea",
                        rows: "5",
                        error: _vm.message_error,
                        "aria-required": "true"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "error",
                            fn: function(ref) {
                              var error = ref.error
                              return [
                                _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "slide-in-bottom",
                                      mode: "out-in"
                                    }
                                  },
                                  [
                                    error
                                      ? _c("div", { key: error }, [
                                          _vm._v(_vm._s(error))
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3034400235
                      ),
                      model: {
                        value: _vm.message,
                        callback: function($$v) {
                          _vm.message = $$v
                        },
                        expression: "message"
                      }
                    },
                    [
                      _vm._v("\n                " + _vm._s(_vm.messageLabel)),
                      _c("span", { attrs: { "aria-hidden": "true" } }, [
                        _vm._v("*")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "form__cols form__submit modal-form-claim__submit"
                    },
                    [
                      _vm.auth_IsGuest
                        ? _c(
                            "div",
                            { staticClass: "modal-form-claim__policy" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.policy,
                                    expression: "policy"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "modal-form-claim-policy",
                                  "aria-required": "true",
                                  "aria-describedby":
                                    "modal-form-claim-policy-error"
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.policy)
                                    ? _vm._i(_vm.policy, null) > -1
                                    : _vm.policy
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.policy,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.policy = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.policy = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.policy = $$c
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "modal-form-claim__policy-label",
                                  attrs: { for: "modal-form-claim-policy" }
                                },
                                [
                                  _vm._v(
                                    "\n                        Согласен с\n                        "
                                  ),
                                  _c(
                                    "v-link",
                                    {
                                      attrs: {
                                        href: "/policy",
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                            условиями обработки персональных данных\n                        "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-button",
                        {
                          staticClass: "modal-form-claim__submit-btn",
                          attrs: { type: "submit", title: "Отправить" }
                        },
                        [
                          _vm._v(
                            "\n                    Отправить\n                "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.auth_IsGuest
                    ? _c(
                        "div",
                        { staticClass: "modal-form-claim__error" },
                        [
                          _c(
                            "transition",
                            {
                              attrs: { name: "slide-in-bottom", mode: "out-in" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.policy_error,
                                      expression: "policy_error"
                                    }
                                  ],
                                  staticClass:
                                    "error-message modal-form-claim__error-message",
                                  attrs: {
                                    id: "modal-form-claim-policy-error",
                                    role: "alert"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.policy_error) +
                                      "\n                    "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }