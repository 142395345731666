var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { staticClass: "form form-login", attrs: { novalidate: "" } },
    [
      _c(
        "div",
        { staticClass: "form-login__container form-login__header" },
        [
          _c("h3", { staticClass: "form__hl form-login__hl" }, [
            _vm._v("Вход через SMS")
          ]),
          _vm._v(" "),
          _c(
            "v-link",
            {
              staticClass: "form-login__register",
              attrs: { href: "/profile/register" }
            },
            [_vm._v("Регистрация")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-login__container" },
        [
          _c(
            "v-input",
            {
              directives: [
                {
                  name: "mask",
                  rawName: "v-mask",
                  value: "+7(###) ###-##-##",
                  expression: "'+7(###) ###-##-##'"
                }
              ],
              staticClass: "form__col",
              attrs: {
                type: "tel",
                placeholder: "+7(___) ___-___-__",
                error: _vm.phoneError,
                "aria-required": "true"
              },
              scopedSlots: _vm._u([
                {
                  key: "error",
                  fn: function(ref) {
                    var error = ref.error
                    return [
                      _c(
                        "transition",
                        { attrs: { name: "slide-in-bottom", mode: "out-in" } },
                        [
                          error
                            ? _c("div", { key: error }, [_vm._v(_vm._s(error))])
                            : _vm._e()
                        ]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.phone,
                callback: function($$v) {
                  _vm.phone = $$v
                },
                expression: "phone"
              }
            },
            [
              _vm._v("\n            Телефон\n            "),
              _c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("*")])
            ]
          ),
          _vm._v(" "),
          _vm.isSmsLogin
            ? _c(
                "v-input",
                {
                  staticClass: "form__col",
                  attrs: {
                    placeholder: "Введите смс-код",
                    error: _vm.smsCodeError,
                    "aria-required": "true"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "error",
                        fn: function(ref) {
                          var error = ref.error
                          return [
                            _c(
                              "transition",
                              {
                                attrs: {
                                  name: "slide-in-bottom",
                                  mode: "out-in"
                                }
                              },
                              [
                                error
                                  ? _c("div", { key: error }, [
                                      _vm._v(_vm._s(error))
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3034400235
                  ),
                  model: {
                    value: _vm.smsCode,
                    callback: function($$v) {
                      _vm.smsCode = $$v
                    },
                    expression: "smsCode"
                  }
                },
                [
                  _vm._v("\n            Смс-код\n            "),
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("*")
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form__cols form__submit form-login__submit" },
            [
              !_vm.isSmsLogin
                ? _c(
                    "v-button",
                    {
                      staticClass: "form-login__submit-btn",
                      attrs: { type: "submit" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.smsSend()
                        }
                      }
                    },
                    [_vm._v("Отправить")]
                  )
                : _c(
                    "v-button",
                    {
                      staticClass: "form-login__submit-btn",
                      attrs: { type: "submit" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.smsLogin()
                        }
                      }
                    },
                    [_vm._v("Войти")]
                  ),
              _vm._v(" "),
              _vm.isSmsLogin && !_vm.smsNext
                ? _c(
                    "v-button",
                    {
                      staticClass: "form-login__submit-btn",
                      attrs: { type: "submit" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.smsSend()
                        }
                      }
                    },
                    [_vm._v("Еще раз")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.smsNext
            ? _c(
                "div",
                { staticClass: "error-message form__col form-login__error" },
                [
                  _vm._v(
                    "\n            Повторно смс-код можно будет отправить через " +
                      _vm._s(_vm.smsNext) +
                      " сек.\n        "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "error-message form__col form-login__error" },
            [
              _c(
                "transition",
                {
                  attrs: { name: "slide-in-bottom", mode: "out-in", appear: "" }
                },
                [
                  _vm.error
                    ? _c("div", { key: _vm.error, attrs: { role: "alert" } }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.error) +
                            "\n                "
                        )
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }