var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "root",
      staticClass: "v-select",
      class: [
        { "is-disabled": _vm.disabled },
        { "v-select--open": _vm.isOpen }
      ],
      attrs: { tabindex: "0" }
    },
    [
      _c(
        "select",
        {
          staticClass: "v-select__select",
          attrs: { disabled: _vm.disabled, name: _vm.name }
        },
        [
          _vm.selectedItem
            ? _c(
                "option",
                {
                  attrs: { selected: "" },
                  domProps: { value: _vm.getValue(_vm.selectedItem) }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.getDisplayValue(_vm.selectedItem)) +
                      "\n        "
                  )
                ]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "v-select__container",
          class: {
            "v-select__container--open": _vm.isOpen
          },
          on: { click: _vm.open }
        },
        [
          _c(
            "div",
            { staticClass: "v-select__item v-select__item--content" },
            [
              !_vm.selectedItem
                ? _c(
                    "div",
                    { staticClass: "v-select__label v-select__placeholder" },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.placeholder) +
                          "\n            "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedItem
                ? _vm._t(
                    "selected-option",
                    [
                      _c("div", { staticClass: "v-select__label" }, [
                        _vm._v(_vm._s(_vm.getDisplayValue(_vm.selectedItem)))
                      ])
                    ],
                    { item: _vm.selectedItem }
                  )
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c("v-svg", {
            staticClass: "v-select__icon",
            class: { "v-select__icon--is-open": _vm.isOpen },
            attrs: { name: "arrow-down" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: _vm.animation } }, [
        _vm.isOpen
          ? _c(
              "ul",
              { staticClass: "v-select__dropdown" },
              _vm._l(_vm.items, function(item, index) {
                return _c(
                  "li",
                  {
                    key: item[_vm.keyField] || index,
                    staticClass: "v-select__item",
                    class: {
                      "v-select__item--selected":
                        _vm.getValue(_vm.selectedItem) === _vm.getValue(item)
                    },
                    on: {
                      click: function($event) {
                        return _vm.selectOption(item)
                      }
                    }
                  },
                  [
                    _vm._t(
                      "option",
                      [_c("div", [_vm._v(_vm._s(_vm.getDisplayValue(item)))])],
                      { item: item }
                    )
                  ],
                  2
                )
              }),
              0
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }