var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "modal" } },
    [
      _vm.modal_IsOpen
        ? _c(
            "modal",
            {
              staticClass: "modal-login",
              attrs: { type: "auth" },
              on: {
                close: function($event) {
                  return _vm.modal_ChangeState({ name: "login", open: false })
                }
              }
            },
            [
              _c("form-login", {
                staticClass: "modal-login__form",
                attrs: { slot: "body", autofocus: !_vm.isTabletLg },
                slot: "body"
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }