var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "form form-login",
      attrs: { novalidate: "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.sendForm($event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "form-login__container form-login__header" },
        [
          _c("h3", { staticClass: "form__hl form-login__hl" }, [
            _vm._v("Войти")
          ]),
          _vm._v(" "),
          _c(
            "v-link",
            {
              staticClass: "form-login__register",
              attrs: { href: "/profile/register" }
            },
            [_vm._v("Регистрация")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-login__container" },
        [
          _c(
            "v-input",
            {
              directives: [
                {
                  name: "focus",
                  rawName: "v-focus",
                  value: { active: _vm.autofocus, delay: 200 },
                  expression: "{ active: autofocus, delay: 200 }"
                }
              ],
              staticClass: "form__col",
              attrs: {
                placeholder: "Введите ваш email",
                error: _vm.email_error,
                "aria-required": "true"
              },
              scopedSlots: _vm._u([
                {
                  key: "error",
                  fn: function(ref) {
                    var error = ref.error
                    return [
                      _c(
                        "transition",
                        { attrs: { name: "slide-in-bottom", mode: "out-in" } },
                        [
                          error
                            ? _c("div", { key: error }, [_vm._v(_vm._s(error))])
                            : _vm._e()
                        ]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.email,
                callback: function($$v) {
                  _vm.email = $$v
                },
                expression: "email"
              }
            },
            [
              _vm._v("\n            Логин"),
              _c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("*")])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-password",
            {
              staticClass: "form__col form-login__password",
              attrs: {
                placeholder: "Введите пароль",
                error: _vm.password_error,
                "aria-required": "true"
              },
              scopedSlots: _vm._u([
                {
                  key: "error",
                  fn: function(ref) {
                    var error = ref.error
                    return [
                      _c(
                        "transition",
                        { attrs: { name: "slide-in-bottom", mode: "out-in" } },
                        [
                          error
                            ? _c("div", { key: error }, [_vm._v(_vm._s(error))])
                            : _vm._e()
                        ]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.password,
                callback: function($$v) {
                  _vm.password = $$v
                },
                expression: "password"
              }
            },
            [
              _c(
                "div",
                { staticClass: "form-login__password-label" },
                [
                  _c("span", [
                    _vm._v(" Пароль"),
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("*")
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-link",
                    {
                      staticClass: "link--sm",
                      on: {
                        click: function($event) {
                          return _vm.modal_ChangeState({
                            name: "forgot_password",
                            open: true
                          })
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Забыли пароль?\n                "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form__cols form__submit form-login__submit" },
            [
              _c("div", { staticClass: "form__col" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.remember,
                      expression: "remember"
                    }
                  ],
                  attrs: { type: "checkbox", id: "form-login__checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.remember)
                      ? _vm._i(_vm.remember, null) > -1
                      : _vm.remember
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.remember,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.remember = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.remember = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.remember = $$c
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "form-login__submit-label",
                    attrs: { for: "form-login__checkbox" }
                  },
                  [_vm._v("Запомнить меня")]
                )
              ]),
              _vm._v(" "),
              _c(
                "v-button",
                {
                  staticClass: "form__col form-login__submit-btn",
                  attrs: { type: "submit" }
                },
                [_vm._v("Войти")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "error-message form__col form-login__error" },
            [
              _c(
                "transition",
                {
                  attrs: { name: "slide-in-bottom", mode: "out-in", appear: "" }
                },
                [
                  _vm.error
                    ? _c("div", { key: _vm.error, attrs: { role: "alert" } }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.error) +
                            "\n                "
                        )
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form-login__container form-login__footer" }, [
        _c(
          "div",
          { staticClass: "form-login__footer-body" },
          [
            _c("h3", { staticClass: "form-login__footer-hl" }, [
              _vm._v("Войти через соц. сети или СМС:")
            ]),
            _vm._v(" "),
            _vm.socials && _vm.socials.length === 0
              ? _c("div", [_vm._v("Нет прикрепленных социальных сетей.")])
              : _c("v-list", {
                  staticClass: "form-login__footer-list",
                  attrs: { "key-field": "name", items: _vm.socials },
                  scopedSlots: _vm._u([
                    {
                      key: "item",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-link",
                            {
                              staticClass: "form-login__footer-link",
                              attrs: {
                                href: item.link,
                                iconName: _vm.socialIcon(item.name),
                                title: "Войти через " + item.title,
                                "aria-label": "Войти через " + item.title
                              }
                            },
                            [
                              item.name == "sms"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "form-login__sms",
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.modal_ChangeState({
                                            name: "sms_login",
                                            open: true
                                          })
                                        }
                                      }
                                    },
                                    [_c("v-svg", { attrs: { name: "sms" } })],
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      }
                    }
                  ])
                })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show_post_loader,
              expression: "show_post_loader"
            }
          ],
          staticClass: "post-loader"
        },
        [_c("loader")],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }