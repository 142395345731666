var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "modal" } },
    [
      _vm.modal_IsOpen
        ? _c(
            "modal",
            {
              on: {
                close: function($event) {
                  return _vm.modal_ChangeState({ name: "message", open: false })
                }
              }
            },
            [
              _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                _c("p", {
                  domProps: { innerHTML: _vm._s(_vm.modal_State.message) }
                })
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }