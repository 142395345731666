import { mapState, mapActions } from 'vuex';

export default name => {
    const mixin = {
        methods: {
            ...mapActions('modal', {
                modal_ChangeState: 'changeState',
            }),
        },
    };

    if (name) {
        mixin.computed = {
            ...mapState('modal', {
                modal_IsOpen: state => state.items[name].open,
                modal_State: state => state.items[name].state,
            }),
        };
    }
    return mixin;
};
