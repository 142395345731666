var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "burger",
      class: { "is-open": _vm.isOpen },
      on: { click: _vm.onClick }
    },
    [_c("div", { staticClass: "burger__line" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }