<template>
    <transition name="modal">
        <modal @close="modal_ChangeState({ name: 'message', open: false })" v-if="modal_IsOpen">
            <div slot="body">
                <p v-html="modal_State.message" />
            </div>
        </modal>
    </transition>
</template>

<script>
import Modal from '../controls/modal/modal.vue';
import ModalMixin from '../../mixins/modal';

export default {
    name: 'modal-message',
    mixins: [ModalMixin('message')],
    components: {
        Modal,
    },
};
</script>
