<template>
    <footer class="footer-main" v-if="layout_ShowFooter">
        <div class="container">
            <div class="footer-main__wrapper footer-main__wrapper--main">
                <div class="footer-main__wrapper footer-main__wrapper--col">
                    <v-svg name="logo-footer" />
                    <ul v-if="!isDesktop" class="footer-main__agreement text-sm">
                        <li class="footer-main__agreement-item" v-for="item in agreementLinks">
                            <a class="footer-main__link link" target="_blank" :href="item.link">{{
                                item.title
                            }}</a>
                        </li>
                        <li class="footer-main__agreement-item">&copy;&nbsp;iWENGO&nbsp;{{ year }}&nbsp;</li>
                    </ul>
                </div>
                <div class="footer-main__wrapper footer-main__wrapper--col">
                    <ul class="footer-main__list">
                        <li v-for="item in layout_MenuBottom" class="footer-main__item" v-if="item && item.href">
                            <a :href="item.href" >{{ item.name }}</a>
                        </li>
                    </ul>
                    <div class="footer-main__wrapper footer-main__wrapper--bottom">
                        <div class="footer-main__news">
                            <v-input
                                v-model="subscriber.email"
                                class="footer-main__news-input"
                                type="email"
                                placeholder="Введите E-mail"
                                :disabled="!auth_IsGuest"
                            >
                                <span class="footer-main__news-label">Подпишись на новости</span>
                                <template v-slot:after>
                                    <v-button
                                        @click="subscribe"
                                        class="footer-main__news-btn"
                                        icon-name="arrow"
                                        aria-label="Подписаться на новости"
                                    />
                                </template>
                            </v-input>
                        </div>

                        <div class="footer-main__contacts">
                            <v-button
                                class="footer-main__contact-btn"
                                @click="
                                    modal_ChangeState({
                                        name: 'claim',
                                        open: true,
                                        state: { type: 'collaboration' },
                                    })
                                "
                            >
                                Сотрудничество
                            </v-button>
                            <div class="footer-main__contacts footer-main__contacts--no-wrap">
                                <ul class="footer-main__socials">
                                    <li :key="icon.name" v-for="icon in socialIcons">
                                        <a :href="icon.url" :aria-label="icon.name" :rel="icon.rel" target="_blank" @click="sendMetric('click_social', 'click', 'social');">
                                            <v-svg :name="icon.icon"></v-svg>
                                        </a>
                                    </li>
                                </ul>
                                <div class="footer-main__contact">
                                    <a :href="mainPhoneHref" class="comagic_phone" 
                                       @click="showPhoneCallback(); sendMetric('click_phone', 'click', 'phone')">{{ layout_StaticBlock.main_phone }}</a>
                                    <div class="mb-3">понедельник-пятница с 10 до 19<br>по Московскому времени</div>
                                    <a :href="'mailto:' + layout_StaticBlock.main_email">{{
                                        layout_StaticBlock.main_email
                                    }}</a>
                                    <div>отвечаем ежедневно в течение суток</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul v-if="isDesktop" class="footer-main__agreement text-sm">
                        <li class="footer-main__agreement-item" v-for="item in agreementLinks">
                            <a class="footer-main__link link" target="_blank" :href="layout_StaticBlock[item.code]">{{
                                item.title
                            }}</a>
                        </li>
                        <li class="footer-main__agreement-item">&copy;&nbsp;iWENGO&nbsp;{{ year }}&nbsp;</li>
                    </ul>
                </div>
            </div>
        </div>
        <modal-form-subscribe :email="subscriber.email" v-on:showPostLoader="show_post_loader = $event" />
        <modal-form-claim v-on:showPostLoader="show_post_loader = $event" />
        <modal-phone-callback v-on:showPostLoader="show_post_loader = $event" />
        <div v-show="show_post_loader" class="post-loader">
            <loader />
        </div>
    </footer>
</template>

<script>
import VSvg from '../controls/VSvg/VSvg.vue';
import VLink from '../controls/VLink/VLink.vue';
import VButton from '../controls/VButton/VButton.vue';
import VInput from '../controls/VInput/VInput.vue';
import ModalFormClaim from '../modal-form-claim/modal-form-claim.vue';
import ModalFormSubscribe from '../modal-form-subscribe/modal-form-subscribe.vue';
import Loader from '../../components/loader/loader.vue';
import ModalPhoneCallback from '../../components/modal-phone-callback/modal-phone-callback.vue';

import Services from '../../../scripts/services/services';
import Helpers from '../../../scripts/helpers';
import ModalMixin from '../../mixins/modal';
import AuthMixin from '../../mixins/auth';
import UserMixin from '../../mixins/user';
import LayoutMixin from '../../mixins/layout';
import MetricMixin from '../../mixins/metric';
import './layout-footer.css';

export default {
    name: 'layout-footer',
    mixins: [LayoutMixin, ModalMixin(), AuthMixin(false), UserMixin(), MetricMixin],
    components: {
        VSvg,
        VLink,
        VButton,
        VInput,
        Loader,
        ModalPhoneCallback,
        
        ModalFormClaim,
        ModalFormSubscribe,
    },
    //data
    data() {
        return {
            show_post_loader: false,
            subscriber: {
                email: '',
            },
            agreementLinks: [
                 {
                    link: '/policy',
                    code: 'file_policy_privacy',
                    title: 'Политика обработки персональных данных',
                },
                {
                    link: '/terms',
                    code: 'file_eula',
                    title: 'Пользовательское соглашение',
                },
                {
                    link: '/license',
                    code: 'file_license',
                    title: 'Публичная оферта',
                },
                {
                    link: '/law',
                    code: 'file_license',
                    title: 'Образовательная лицензия',
                },
                {
                    link: '/aboutcompany',
                    code: 'aboutcompany',
                    title: 'Сведения об организации',
                },
                {
                    link: '/sitemap',
                    code: 'sitemap',
                    title: 'Карта сайта',
                },
            ],
            socialIcons: [
                /*{
                    name: 'Facebook',
                    icon: 'fb',
                    rel: '',
                    url: 'https://www.facebook.com/iwengo.ru/',
                },*/
                {
                    name: 'Telegram',
                    icon: 'telegram-svg',
                    rel: '',
                    url: 'https://t.me/goecommerce',
                },
                {
                    name: 'Youtube',
                    icon: 'yb',
                    rel: 'nofollow',
                    url: 'https://www.youtube.com/channel/UCfnfRUGH_CuFabZpsrSgmWg/featured',
                },
                /*{
                    name: 'Instagram',
                    icon: 'im',
                    rel: '',
                    url: 'https://www.instagram.com/iwengo.ru/',
                },*/
                {
                    name: 'Yandex',
                    icon: 'yandex',
                    rel: '',
                    url: 'https://yandex.ru/profile/142511780533',
                },
                /*{
                    name: 'Google',
                    icon: 'google',
                    rel: '',
                    url: 'https://g.page/iWENGO/review?kd',
                },*/
            ],
        };
    },
    computed: {
        year() {
            return new Date().getFullYear();
        },
        mainPhoneHref() {
            return 'tel:+' + Helpers.rawPhone(this.layout_StaticBlock.main_phone);
        },
        isDesktop() {
            return this.$mq.desktop;
        },
    },
    methods: {
        showPhoneCallback() {
            // наш режим работы пн-пт с 10 до 19, в нерабочее время показываем попап
            let today = new Date();
            let day = today.getUTCDay();
            if (day === 0 || day === 6) {
                this.modal_ChangeState({ name: 'phone_callback', open: true });
                return false;
            } else {
                let hour = today.getUTCHours();
                if (hour < 7 || hour >= 16) {
                    this.modal_ChangeState({ name: 'phone_callback', open: true });
                    return false;
                }
            }
        },
        subscribe() {
            if (!this.auth_IsGuest) {
                this.sendMetric('form_subscribe', 'form', 'subscribe');

                this.user_Subscribe().then(() =>
                    this.modal_ChangeState({
                        name: 'message',
                        open: true,
                        state: {message: 'Спасибо за подписку'},
                    })
                );
            }
            else this.modal_ChangeState({ name: 'subscribe', open: true });
        },
    },
    beforeMount() {
        this.subscriber.email = !this.auth_IsGuest ? this.user_Email : '';
    },
};
</script>
