export const TYPE_DOCUMENT = 'document';
export const TYPE_COURSE = 'course';
export const TYPE_SERVICE = 'service';
export const TYPE_CONSTRUCT = 'construct';
export const TYPE_COURSE_TARIFF = 'course_tariff';
// тип для избранного, чтобы серверный обработчк добавлял не спан, а сам курс
export const TYPE_COURSE_SPAN = 'course_span';

/**
 * Получение типа продукта
 * @param {*} item
 */
export function typeOfItem(item) {
    if (item.document_id) {
        return TYPE_DOCUMENT;
    } else if (item.course_span_id) {
        return TYPE_COURSE;
    } else if (item.service_id) {
        return TYPE_SERVICE;
    } else if (item.construct_id) {
        return TYPE_CONSTRUCT;
    } else if (item.course_tariff_id) {
        return TYPE_COURSE_TARIFF;
    } else {
        return undefined;
    }
}

/**
 * Получение айдишника продукта в зависимости от типа продукта
 * @param {*} item
 */
export function idProductOfItem(item) {
    switch (typeOfItem(item)) {
        case TYPE_DOCUMENT:
            return item.document_id;
        case TYPE_COURSE:
            return item.course_span_id;
        case TYPE_SERVICE:
            return item.service_id;
        case TYPE_CONSTRUCT:
            return item.construct_id;
        case TYPE_COURSE_TARIFF:
            return item.course_tariff_id;
        default:
            return undefined;
    }
}
